import React, { ReactElement } from 'react';

import parse from 'html-react-parser';

import { deepLinks, DOMAIN } from '@common/const';
import { onlyNumber } from '@common/regExp';

export const numFormat = (num: number): string => {
  return Number(num)
    .toFixed(2)
    .replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
    .split('.')[0];
};

export const formatNumber = (num: string): string => {
  return numFormat(Number(onlyNumber(num)));
};

/** url 파라미터가 딥링크라면, 웹링크로 파싱해서 반환 */
export const parseDeepToWebLink = (url: string) => {
  // 정의되어있는 딥링크 목록을 탐색하여, url 파라미터가 딥링크인지 체크
  const idx = deepLinks.findIndex((link) => url.indexOf(link.deepLink) === 0);

  const isDeepLink = idx >= 0;
  if (!isDeepLink) {
    return {
      isDeepLink,
      deepLink: '',
      webLink: url,
    };
  }

  const { deepLink, webLink } = deepLinks[idx];

  // 딥링크를 웹링크로 변환
  const parseWebLink = url.replace(deepLink, webLink);

  return {
    isDeepLink, // 딥링크가 맞는지 판별
    deepLink, // 웹뷰 내에서 사용할 딥링크
    webLink: parseWebLink, // PC+Mobile 환경에서 사용할 웹링크
  };
};

/** url 파라미터가 외부사이트인지 판별 */
export const isExternalLink = (url: string) =>
  url.indexOf('/') !== 0 && url.indexOf(DOMAIN || '') !== 0;

/** url 정보 추출 */
export const parseLinks = (url: string) => {
  const parseDeepLink = parseDeepToWebLink(url);
  const isExternal = isExternalLink(url);

  return {
    ...parseDeepLink,
    isExternal, // 새 창으로 열어야할 외부링크인지 판별
  };
};

/** number 입력 시 원화로 변경 */
export const getWonUnit = (price: number, isWonMark = true): string => {
  if (isNaN(Number(price))) return '';
  const commaPrice = price.toLocaleString('en-US');
  return isWonMark ? `${commaPrice}원` : commaPrice;
};

export const getPriceFormat = (price: number, isWonMark = true): string => {
  if (isNaN(Number(price))) return '';
  const commaPrice = price.toLocaleString('en-US');
  return isWonMark ? `${commaPrice}` : commaPrice;
};

/** 날짜 입력 시 경과시간으로 변경 */
export const getElapsedTime = (date: string): string => {
  const createdTime = new Date(date.replace(/-/g, '/'));
  const createdDay = date.split(' ')[0];
  const current = new Date();

  const gapSec = Math.floor((Number(current) - Number(createdTime)) / 1000);

  if (gapSec === 0) return '방금 전';

  if (gapSec < 60) return `${gapSec}초 전`;

  const gapMin = Math.floor(gapSec / 60);
  if (gapMin < 60) return `${gapMin}분 전`;

  const gapHour = Math.floor(gapMin / 60);
  if (gapHour < 24) return `${gapHour}시간 전`;

  const gapDay = Math.floor(gapHour / 24);
  if (gapDay < 30) return `${gapDay}일 전`;

  return createdDay;
};

/** 최대 길이로 Text 자르고 ⋯추가 */
export const changeOmitText = (maxLength: number, text: string): string => {
  return text.length > maxLength ? `${text.slice(0, maxLength)}⋯` : text;
};

/** hex값을 rgba로 변경 */
export const hexToRgba = (hex: string, alpha: number): string => {
  const regex = /^#([A-Fa-f0-9]{3}){1,2}$/;
  if (regex.test(hex)) {
    let hexStr = hex.replace('#', '');
    if (hexStr.length === 3) {
      hexStr = `${hexStr[0]}${hexStr[0]}${hexStr[1]}${hexStr[1]}${hexStr[2]}${hexStr[2]}`;
    }
    const r = parseInt(hexStr.slice(0, 2), 16);
    const g = parseInt(hexStr.slice(2, 4), 16);
    const b = parseInt(hexStr.slice(4, 6), 16);

    return `rgba(${r},${g},${b},${alpha})`;
  }
  throw new Error('Bad Hex');
};

/** string response 개행 처리 */
export const replaceCRLF = (string: string): string => {
  return string
    .replace(/\n\n/gi, '<br><br>')
    .replace(/\r\n/gi, '<br>')
    .replace(/\r/gi, '<br>')
    .replace(/\n/gi, '<br>');
};

/** string을 받아 개행처리 후 ReactElement로 return  */
export const htmlStringToReactElement = (
  string: string,
): string | ReactElement | ReactElement[] => {
  return parse(replaceCRLF(string));
};

/** 날짜 입력시 시행일자로 리턴 */
export const returnImplementDate = (targetLength: number, date: string) => {
  const changeYear = date.replace('-', '년 ');
  const changeMonth = changeYear.replace('-', '월 ');
  return changeMonth.padEnd(targetLength, '일 시행');
};
