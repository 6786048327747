/* eslint-disable @typescript-eslint/no-empty-function */
import React, {
  ChangeEventHandler,
  KeyboardEventHandler,
  forwardRef,
} from 'react';
import { DebounceInput } from 'react-debounce-input';

import { MOBILE_WIDTH } from '@common/constants/MEDIAS';
import { css } from '@emotion/react';

import { palette } from '../../../lib/styles/palette';
import Button from '../Button';
import Icon from '../Icon';

interface SearchBarSecondaryProps {
  placeHolder?: string;
  inputValue?: string | string[];
  onFocus?: () => void;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  onKeyPress?: KeyboardEventHandler<HTMLInputElement>;
  clearInputValue?: () => void;
}

const SearchBarMobile = forwardRef(
  (
    {
      placeHolder = '검색어를 입력해주세요.',
      inputValue = '',
      onFocus,
      onChange = () => {},
      onKeyPress,
      clearInputValue,
    }: SearchBarSecondaryProps,
    ref?: React.Ref<HTMLInputElement>,
  ) => (
    <div css={wrap}>
      <Icon icon="search" size="1.3rem" stroke={palette.gray5} />
      <DebounceInput
        inputRef={ref}
        type="text"
        id="searchStr"
        name="상품 검색"
        value={(inputValue as string) || ''}
        placeholder={placeHolder}
        autoComplete="off"
        onFocus={onFocus}
        onChange={onChange}
        onKeyPress={onKeyPress}
        debounceTimeout={500}
      />
      {inputValue && (
        <Button type="link" padding="0" onClick={clearInputValue}>
          <Icon
            size="1.3rem"
            icon="xCircleFill"
            stroke={palette.white}
            color={palette.gray5}
          />
        </Button>
      )}
    </div>
  ),
);

const wrap = css`
  display: none;
  margin: 16px 0;

  @media (max-width: ${MOBILE_WIDTH}) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 2.5rem;
    padding: 0 0.8rem;
    background: ${palette.gray0};
    border-radius: 0.5rem;
    input {
      width: 100%;
      height: 100%;
      padding: 0 0.4rem;
      background: none;
      border: none;
      outline: none;
      font-size: 0.95rem;
      color: #333;
    }

    input::placeholder {
      color: #ccc;
    }
  }
`;

export default SearchBarMobile;
