import React, {
  ReactElement,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from 'react';

import { useRecoilState } from 'recoil';

import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { toastMessageState } from '@recoils/popup/atom';

interface ToastProps {
  children: ReactNode;
  onShow: () => void;
  onHide: () => void;
}

const Toast = ({ children, onShow, onHide }: ToastProps): ReactElement => {
  // state for toast animation (toast Show state true at frist)
  const [isShowToast, setIsShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useRecoilState(toastMessageState);

  const timeOut = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (timeOut.current) clearTimeout(timeOut.current);

    // 1. show toast popup
    onShow();

    // 2. hide toast popup
    timeOut.current = setTimeout(() => {
      onHide();
      setToastMessage(null);
      setIsShowToast(false);
    }, 3000);

    setIsShowToast(true);
  }, [toastMessage]);

  return (
    <ToastWrap isShowToast={isShowToast}>
      <div className="toastWrapper">
        <div className="toast">
          <span className="text">{children}</span>
        </div>
      </div>
    </ToastWrap>
  );
};

interface ToastWrapProps {
  isShowToast: boolean;
}
const toastAnimationStyle = ({ isShowToast }: ToastWrapProps) =>
  css`
    bottom: ${isShowToast ? '3rem' : '-3rem'};
    opacity: ${isShowToast ? 1 : 0};
  `;

const ToastWrap = styled.div<ToastWrapProps>`
  .toastWrapper {
    width: 100%;
    height: auto;
    text-align: center;
    position: fixed;
    transition: all 0.3s ease-in-out;
    z-index: 9999;

    .toast {
      padding: 10px 20px;
      width: -moz-fit-content;
      width: fit-content;
      margin: 0 auto;
      background-color: rgba(51, 51, 51, 0.8);
      border-radius: 50px;
    }

    .text {
      font-size: 15px;
      line-height: 22px;
      color: #fff;
      display: flex;
      flex-direction: column;
    }
    ${toastAnimationStyle};
  }
`;

export default Toast;
