import { ApiResponse } from '@type/web/api';
import {
  CategoryListData,
  CategoryHierarchyData,
  CategoryDepthData,
  CategoryType,
} from '@type/web/category';

import { axiosRequest, AxiosDomain } from '@api/config';

const axiosBase = AxiosDomain.mainBase;

/** 카테고리 전체 목록 조회 */
export const getCategories = (): Promise<ApiResponse<CategoryType[]>> => {
  const path = `/common/category/total`;
  return axiosRequest<CategoryType[]>(axiosBase, 'get', path);
};

/** 특정 카테고리 목록 조회 */
export const getTargetCategories = (
  categorySeq: number,
): Promise<ApiResponse<CategoryListData>> => {
  const path = `/common/category/list`;
  const query = `?parentSeq=${categorySeq}`;
  return axiosRequest<CategoryListData>(axiosBase, 'get', path + query);
};

/** 상품 카테고리 계층구조 조회 */
export const getCategoryHierarchy = (
  categorySeq: number,
): Promise<ApiResponse<CategoryType[]>> => {
  const path = `/common/category/${categorySeq}/hierarchy`;
  return axiosRequest<CategoryType[]>(axiosBase, 'get', path);
};

/** 카테고리 depth 별 seq 조회 */
export const getSequencebyCategoryDepth = (
  categorySeq: number,
): Promise<ApiResponse<CategoryDepthData>> => {
  const path = `/common/category/parent/${categorySeq}`;
  return axiosRequest<CategoryDepthData>(axiosBase, 'get', path);
};
