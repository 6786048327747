import Link from 'next/link';

import React, { ReactElement, useCallback, useRef } from 'react';

import Autoplay from 'embla-carousel-autoplay';
import useEmblaCarousel from 'embla-carousel-react';

import { TABLET_WIDTH } from '@common/constants/MEDIAS';
import { changeOmitText } from '@common/parser';
import { css } from '@emotion/react';
import { PopularWordItem } from '@type/api/base/mainpage';

import { palette } from '@lib/styles/palette';

import useResponsive from '@hook/useResponsive';

import Icon from '@components/common/Icon/Icon';

interface RankKeywordPreviewSwipeProps {
  keywordList: PopularWordItem[];
  setIsShowDetail: (
    cbOrIsShow: ((isShow: boolean) => boolean) | boolean,
  ) => void;
}

function RankKeywordDetailTab({
  keywordList,
  setIsShowDetail,
}: RankKeywordPreviewSwipeProps): ReactElement {
  const { isPC } = useResponsive();

  const onMouseEnterHandler = useCallback(() => {
    if (isPC) setIsShowDetail(true);
  }, [isPC]);

  const autoplay = useRef(
    Autoplay(
      { delay: 3000, stopOnInteraction: false },
      (emblaRoot) => emblaRoot.parentElement,
    ),
  );

  const [emblaRef] = useEmblaCarousel(
    {
      axis: 'y',
      draggable: false,
      loop: true,
    },
    [autoplay.current],
  );

  // 실시간 검색 상세 Toggle 동작
  const onClickToggleButton = () => {
    if (!isPC) setIsShowDetail((v) => !v);
  };

  if (!keywordList.length) return <></>;

  return (
    <div css={wrapperStyle} onMouseEnter={onMouseEnterHandler}>
      {!isPC && <div className="label">실시간</div>}
      <div className="previewSwipe" ref={emblaRef}>
        <div className="inner">
          {keywordList
            .slice(0, 20)
            .map((keyword: PopularWordItem, i: number) => {
              const rankNum = ('0' + (i + 1)).slice(-2);
              return (
                <div
                  key={`${rankNum}_${keyword.popularWord}`}
                  className="keyword"
                >
                  <div>
                    <span>{rankNum}</span>
                    <Link href={`/search/${keyword.popularWord}`} passHref>
                      <a>{changeOmitText(15, keyword.popularWord)}</a>
                    </Link>
                  </div>
                  <button
                    type="button"
                    aria-label="close"
                    className="button"
                    onClick={() => {
                      onClickToggleButton();
                    }}
                  >
                    <Icon icon="arrowDown" size={12} stroke={palette.black10} />
                  </button>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
}

const wrapperStyle = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  overflow: hidden;

  .label {
    display: flex;
    align-items: center;
    height: 23px;
    padding: 0 8px;
    font-weight: 700;
    font-size: 12px;
    color: ${palette.white};
    background: ${palette.orange9};
    border-radius: 4px;
  }

  .previewSwipe {
    flex: 1;
    padding: 0 12px;
    .inner {
      height: 44px;
      .keyword {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        height: 100%;

        .button {
          height: 44px;
          padding: 0 4px 0 10px;
          background: none;
        }

        span {
          margin-right: 8px;
          font-weight: 700;
          color: ${palette.orange9};
        }
        a {
          color: ${palette.black10};
          font-size: 16px;
        }
      }
    }
  }

  @media (max-width: ${TABLET_WIDTH}) {
    height: 56px;
    .inner {
      .keyword {
        a {
          font-size: 14px;
        }

        .button {
          height: 44px;
          padding: 0 4px 0 10px;
          background: none;
        }
      }
    }
  }
`;

export default RankKeywordDetailTab;
