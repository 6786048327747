import Link from 'next/link';

import React, { ReactElement } from 'react';

import { MOBILE_WIDTH, TABLET_WIDTH } from '@common/constants/MEDIAS';
import * as AppInterface from '@common/interface';
import { isAppDevice } from '@common/webview';
import { css } from '@emotion/react';
import { HeaderDefaultOptions, PrimaryResponseType } from '@type/web/header';

import makeCssTextEllipsis from '@lib/styles/makeCssTextEllipsis';
import { palette } from '@lib/styles/palette';

import Logo from '@components/common/Logo/Logo';
import HeaderRightButtons from '@components/web/Header/HeaderRightButtons';
import SpaceLayout from '@components/web/Layout/SpaceLayout';

type HeaderPrimaryMobileProps = HeaderDefaultOptions & PrimaryResponseType;
function HeaderPrimaryMobileTablet({
  isTopPosition,
  response,
}: HeaderPrimaryMobileProps): ReactElement {
  return (
    <div css={wrapperStyle}>
      <SpaceLayout>
        <div css={primaryMobile}>
          <Link href="/">
            <a
              aria-label="홈"
              className="logo"
              onClick={() => {
                if (isAppDevice()) {
                  AppInterface.closeWebView();
                }
              }}
            >
              <Logo
                logoType="ONLY_ICON"
                color={isTopPosition ? palette.white : palette.orange9}
              />
            </a>
          </Link>

          {/* {response?.title && (
            <div
              className={response?.onlyMobileTitle ? 'hide-title' : 'title'}
              css={makeCssTextEllipsis({ lineCount: 1 })}
            >
              {response.title}
            </div>
          )} */}
          <HeaderRightButtons
            isTopPosition={isTopPosition}
            btns={response?.rightButtons}
            onClickShareData={response?.rightShareBtnHandler}
          />
        </div>
      </SpaceLayout>
    </div>
  );
}

const wrapperStyle = css`
  display: none;

  @media (max-width: ${TABLET_WIDTH}) {
    display: block;
  }
`;

const primaryMobile = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 44px;
  .logo {
    min-width: 44px;
  }
  .title {
    max-width: 50%;
  }

  .hide-title {
    display: none;

    @media (max-width: ${MOBILE_WIDTH}) {
      display: block;
    }
  }
`;

export default HeaderPrimaryMobileTablet;
