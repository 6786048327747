export const defaultSeoConfig = {
  title: undefined,
  titleTemplate: '%s | 중고나라',
  defaultTitle: '사기조회와 거래를 한번에, 중고나라',
  description:
    '안전결제를 통한 사기조회와 피해 보상까지, 카드결제 가능한 중고나라에서 거래하세요.',
  canonical: 'https://web.joongna.com/',
  openGraph: {
    type: 'website',
    locale: 'ko_KR',
    url: 'https://web.joongna.com/',
    site_name: '중고나라',
    title: '사기조회와 거래를 한번에, 중고나라',
    description:
      '안전결제를 통한 사기조회와 피해 보상까지, 카드결제 가능한 중고나라에서 거래하세요.',
    images: [
      {
        url: 'https://img2.joongna.com/mystore/share/img_jn_url_share%401x.png',
        alt: '중고나라',
        width: 800,
        height: 600,
      },
    ],
  },
  // twitter: {
  //   handle: '@중고나라',
  //   site: '@https://web.joongna.com/',
  //   cardType: 'summary_large_image',
  // },
};

export const defaultAdditionalMetaTags = [
  { name: 'application-name', content: '중고나라' },
  { property: 'dc:creator', content: '중고나라' },
  {
    property: 'x-ua-compatible',
    content: 'IE=edge',
  },
];

export const defaultAdditionalLinkTags = [
  {
    rel: 'icon',
    href: '/favicon.ico',
  },
  { rel: 'shortcut icon', href: '/favicon.ico' },
  {
    rel: 'apple-touch-icon',
    href: '/favorit_icon_ios.png',
    sizes: '76x76',
  },
  {
    rel: 'manifest',
    href: '/manifest.json',
  },
];
