/* eslint-disable @typescript-eslint/no-empty-function */
import React, {
  ChangeEventHandler,
  KeyboardEventHandler,
  forwardRef,
} from 'react';
import { DebounceInput } from 'react-debounce-input';

import { MOBILE_WIDTH, TABLET_WIDTH } from '@common/constants/MEDIAS';
import { css } from '@emotion/react';

import { palette } from '@lib/styles/palette';

import Button from '@components/common/Button/Button';
import Icon from '@components/common/Icon/Icon';

interface SearchBarPrimaryProps {
  placeHolder?: string;
  inputValue?: string | string[];
  onFocus?: () => void;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  onKeyPress?: KeyboardEventHandler<HTMLInputElement>;
  clearInputValue?: () => void;
}

const SearchBarPC = forwardRef(
  (
    {
      placeHolder = 'Search',
      inputValue = '',
      onFocus,
      onChange = () => {},
      onKeyPress,
      clearInputValue,
    }: SearchBarPrimaryProps,
    ref?: React.Ref<HTMLInputElement>,
  ) => (
    <div css={wrap}>
      <Icon icon="search" size="1.4rem" stroke={palette.gray6} />
      <DebounceInput
        inputRef={ref}
        type="text"
        id="searchStr"
        name="상품 검색"
        value={(inputValue as string) || ''}
        placeholder={placeHolder}
        autoComplete="off"
        onFocus={onFocus}
        onChange={onChange}
        onKeyPress={onKeyPress}
        debounceTimeout={500}
      />
      {inputValue && (
        <Button type="link" padding="0" onClick={clearInputValue}>
          <Icon
            icon="xCircleFill"
            stroke={palette.white}
            color={palette.gray5}
          />
        </Button>
      )}
    </div>
  ),
);

const wrap = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 3.6rem;
  border-bottom: 2px solid #333;

  input {
    margin-left: 0.5rem;
    height: 3.5rem;
    width: 100%;
    border: none;
    outline: none;
    font-size: 1.25rem;
    color: ${palette.black10};
  }

  input::placeholder {
    color: ${palette.gray5};
  }

  @media (max-width: ${TABLET_WIDTH}) {
    margin: 12px 0 20px 0;
  }

  @media (max-width: ${MOBILE_WIDTH}) {
    display: none;
  }
`;

export default SearchBarPC;
