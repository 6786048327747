import { ReactElement, ReactNode } from 'react';

import { css } from '@emotion/react';

interface ElementProps {
  children: ReactNode;
}

function CenterLayout({ children }: ElementProps): ReactElement {
  return <div css={centerWrap}>{children}</div>;
}

const centerWrap = css`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export default CenterLayout;
