import React, { ReactElement, useState } from 'react';
import { useQuery } from 'react-query';

import { TABLET_WIDTH } from '@common/constants/MEDIAS';
import { css } from '@emotion/react';

import { getPopularKeywords } from '@api/search/search';

import RankKeywordDetailSwipe from './RankKeywordDetailSwipe';
import RankKeywordDetailTab from './RankKeywordDetailTab';
import RankKeywordPreviewSwipe from './RankKeywordPreviewSwipe';

function RankKeyword(): ReactElement {
  const { data } = useQuery('popularKeywords', () => getPopularKeywords(), {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });
  const popularWordsData = data?.data;
  const [isShowDetail, setIsShowDetail] = useState(false);

  return (
    <div css={wrapperStyle}>
      {isShowDetail && popularWordsData ? (
        <>
          <RankKeywordDetailTab
            keywordList={popularWordsData.wordItems}
            reloadDateTime={popularWordsData.reloadDateTime}
            setIsShowDetail={setIsShowDetail}
          />
          <RankKeywordDetailSwipe
            keywordList={popularWordsData.wordItems}
            reloadDateTime={popularWordsData.reloadDateTime}
            setIsShowDetail={setIsShowDetail}
          />
        </>
      ) : (
        popularWordsData && (
          <RankKeywordPreviewSwipe
            keywordList={popularWordsData.wordItems}
            setIsShowDetail={setIsShowDetail}
          />
        )
      )}
    </div>
  );
}

const wrapperStyle = css`
  width: 100%;
  height: auto;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 11;

  @media (max-width: ${TABLET_WIDTH}) {
    position: unset;
  }
`;

export default RankKeyword;
