import { useRecoilState } from 'recoil';

import { MAX_RECENT_SEARCHES } from '@common/const';

import { RecentSearchesType, recentSearchesAtom } from '@recoils/search/atom';

interface RecentSearchesReturnType {
  recentSearches: RecentSearchesType;
  clearRecentSearches: () => void;
  addRecentSearches: (keyword: string) => void;
}

function useRecentSearches(): RecentSearchesReturnType {
  const [recentSearches, setRecentSearches] =
    useRecoilState(recentSearchesAtom);

  const clearRecentSearches = () => {
    setRecentSearches([]);
    localStorage.removeItem('recentSearches');
  };

  const addRecentSearches = (keyword: string) => {
    const newRecentSearches = [...recentSearches];
    const idx = newRecentSearches.indexOf(keyword);

    if (idx !== -1) newRecentSearches.splice(idx, 1);
    else if (newRecentSearches.length >= MAX_RECENT_SEARCHES)
      newRecentSearches.pop();

    newRecentSearches.unshift(keyword);

    localStorage.setItem('recentSearches', JSON.stringify(newRecentSearches));
    setRecentSearches(newRecentSearches);
  };

  return { recentSearches, clearRecentSearches, addRecentSearches };
}

export default useRecentSearches;
