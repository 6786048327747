import Link from 'next/link';

import React, { ReactElement, useEffect, useRef, useState } from 'react';

import { TABLET_WIDTH, MOBILE_WIDTH } from '@common/constants/MEDIAS';
import { css } from '@emotion/react';
import { HeaderDefaultOptions, NavigationResponseType } from '@type/web/header';

import { palette } from '@lib/styles/palette';

import Button from '@components/common/Button/Button';
import HeaderCategories from '@components/web/Header/HeaderCategories';
import useHeaderNavigation from '@components/web/Header/hook/useHeaderNavigation';
import SpaceLayout from '@components/web/Layout/SpaceLayout';
import { PcTemplate } from '@components/web/common/responsiveTemplateStyle';

type HeaderNavigationPCProps = HeaderDefaultOptions & NavigationResponseType;

type QuickMenuOnMouseEnterType =
  | React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>
  | undefined;

function HeaderNavigationPCTemplete({
  isShowBackground,
  isTopPosition = true,
}: HeaderNavigationPCProps): ReactElement {
  const [isWhite, setIsWhite] = useState(false);

  const quickMenuWrap = useRef<HTMLUListElement>(null);
  const serviceMenuWrap = useRef<HTMLUListElement>(null);
  const menuDot = useRef<HTMLDivElement>(null);

  const [categoryPopup, setCategoryPopup] = useState(false);
  const {
    naviMenu: { activeQuickIndex, quickMenu, serviceMenu },
    activeMenu,
    dotPosLeft,
    setInitDot,
    setHoverDot,
  } = useHeaderNavigation(quickMenuWrap, serviceMenuWrap);

  const onMouseLeave = () => {
    setCategoryPopup(false);
  };

  useEffect(() => {
    if (!menuDot?.current) return;

    menuDot.current.style.left = `${dotPosLeft}px`;
  }, [dotPosLeft]);

  useEffect(() => {
    setIsWhite(isTopPosition && !isShowBackground);
  }, [isTopPosition, isShowBackground]);

  return (
    <div css={wrapperStyle}>
      <SpaceLayout>
        <div css={navigation(isWhite)}>
          <div className="menuWrap quickMenuWrap">
            <ul onMouseLeave={setInitDot} ref={quickMenuWrap}>
              {quickMenu.map((menu, i: number) => {
                return (
                  <li
                    key={menu.title}
                    className={`quick-li`}
                    css={activeCss(activeQuickIndex === i)}
                  >
                    <Link href={menu.to}>
                      <a
                        css={quickItemStyle(isWhite)}
                        onMouseLeave={setInitDot}
                        onMouseEnter={(e: QuickMenuOnMouseEnterType) => {
                          setHoverDot(e);
                          if (menu.isCategory) setCategoryPopup(true);
                        }}
                      >
                        {menu.title}
                      </a>
                    </Link>
                    {menu.isCategory && categoryPopup && (
                      <HeaderCategories onMouseLeave={onMouseLeave} />
                    )}
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="menuWrap serviceWrap">
            <ul onMouseLeave={setInitDot} ref={serviceMenuWrap}>
              {serviceMenu.map((menu: { title: string; to: string }, i) => (
                <li
                  key={menu.title}
                  className={'quick-li'}
                  css={activeCss(activeQuickIndex === i)}
                >
                  <Link href={menu.to}>
                    <a
                      css={serviceItemStyle(isWhite)}
                      onMouseLeave={setInitDot}
                      onMouseEnter={(e: QuickMenuOnMouseEnterType) => {
                        setHoverDot(e);
                      }}
                    >
                      {menu.title}
                    </a>
                  </Link>
                </li>
              ))}
            </ul>
          </div>
          <div css={dot(activeMenu, isWhite)} ref={menuDot} />
        </div>
      </SpaceLayout>
    </div>
  );
}

const wrapperStyle = css`
  min-width: 300px;
`;

const navigation = (isWhite: boolean) => css`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  .menuWrap {
    > ul {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 64px;

      > li {
        padding: 0 14px;
        flex-shrink: 0;
      }
    }
  }

  @media (max-width: ${MOBILE_WIDTH}) {
    justify-content: flex-start;
    flex: 1 0 auto;

    /** 기존에 dot position을 자바스크립트로 동적 변경을 하였는데, 
    해당 position 값의 기준을 선택된 li element 요소의 너비로 계산을 하였음
    
    반응형 구현 시, pc와 달리 모바일/태블릿은 menu가 가운데가 아닌 왼쪽을 기준으로 정렬되어야 했으며
    이를 위해서는 quickMenuWrap 첫번째 요소의 padding을 제거하고 
    2번째 li의 left padding을 2배로 설정하였음
    */
    .quickMenuWrap li:first-of-type {
      padding: 0;
    }

    .quickMenuWrap li:nth-child(2) {
      padding-left: 28px;
    }
  }

  @media (max-width: ${TABLET_WIDTH}) {
    justify-content: flex-start;
    flex: 1 0 auto;

    .quickMenuWrap li:first-of-type {
      padding: 0;
    }

    .quickMenuWrap li:nth-child(2) {
      padding-left: 28px;
    }
  }
`;

const quickItemStyle = (isWhite: boolean) => css`
  display: inline-flex;
  height: 30px;
  color: ${isWhite ? palette.white : palette.black10};
  justify-content: center;
  align-items: center;

  :hover {
    color: ${isWhite ? palette.white : palette.black10};
    font-weight: 500;
  }
`;

const serviceItemStyle = (isWhite: boolean) => css`
  display: inline-flex;
  height: 30px;
  color: ${isWhite ? palette.white : palette.gray6};
  justify-content: center;
  align-items: center;

  :hover {
    color: ${isWhite ? palette.white : palette.gray6};
    font-weight: 500;
  }
`;

const dot = (activeMenu: 'quickMenu' | 'serviceMenu', isWhite?: boolean) => css`
  width: 4px;
  height: 4px;
  background: ${isWhite
    ? palette.white
    : activeMenu === 'quickMenu'
    ? palette.black10
    : palette.gray6};
  border-radius: 50%;
  position: absolute;
  top: 8px;
  transform: translateX(-50%);
  transition: left 0.3s ease-in-out;
`;

const activeCss = (isActive: boolean) => css`
  a {
    ${isActive && { fontWeight: 500, opacity: '1 !important' }};
  }
`;

export default HeaderNavigationPCTemplete;
