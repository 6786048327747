import React, { ReactElement } from 'react';

import { useSetRecoilState } from 'recoil';

import { mainList } from '@data/categoryList';
import { css } from '@emotion/react';
import { CategoryListData } from '@type/web/category';
import { HeaderOptions } from '@type/web/header';

import HeaderNavigation from '@components/web/Header/HeaderNavigation/HeaderNavigation';
import HeaderPrimary from '@components/web/Header/HeaderPrimary/HeaderPrimary';

import { categorys as atomCategorys } from '@recoils/category/atom';

export function Header({
  isFixed,
  isShowBackground,
  isTopPosition,
  types,
}: HeaderOptions): ReactElement {
  const setCategorys = useSetRecoilState(atomCategorys);
  if (mainList !== undefined) {
    const categoryData = mainList.data as CategoryListData;
    setCategorys(categoryData);
  }

  return (
    <header css={header}>
      {types?.map((headerType): ReactElement => {
        switch (headerType.type) {
          case 'primary':
            return (
              <HeaderPrimary
                isFixed={isFixed}
                isShowBackground={isShowBackground}
                isTopPosition={isTopPosition}
                response={headerType.response}
                key={headerType.type}
              />
            );
          case 'navigation':
            return (
              <HeaderNavigation
                isFixed={isFixed}
                isShowBackground={isShowBackground}
                isTopPosition={isTopPosition}
                response={headerType.response}
                key={headerType.type}
              />
            );
          case 'custom': {
            const CustomComponent = headerType.response;
            return <CustomComponent key={headerType.type} />;
          }
          default:
            return <></>;
        }
      })}
    </header>
  );
}

const header = css`
  width: 100%;
`;
export default Header;
