/** 문자열 입력 시 숫자 제거 */
export const onlyNumber = (string = ''): string => {
  return string.trim().replace(/[^0-9]/g, '');
};

/** 특정 script 내 fontsize 변경 */
export const convertFontSizeInTextAraea = (
  word: string,
  isViewPort: boolean,
  ratio: number,
): string => {
  const sizeRatio = isViewPort ? ratio + 2 : ratio;
  const fontRegExp = /(\d*\.?\d{1,})(pt|px)/g;

  const getProperFontSize = (match: string, fontsize: string): string => {
    const properFontSize = `${String(
      (Number(fontsize) / sizeRatio) * (sizeRatio + 2),
    )}px`;
    return properFontSize;
  };
  return word.replace(fontRegExp, getProperFontSize);
};
