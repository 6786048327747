import React, { ReactElement } from 'react';

import { useRecoilValue } from 'recoil';

import { css } from '@emotion/react';

import { palette } from '@lib/styles/palette';

import Button from '@components/common/Button/Button';
import SpaceLayout from '@components/web/Layout/SpaceLayout';

import { categorys } from '@recoils/category/atom';

interface HeaderCategoriesProps {
  onMouseLeave: () => void;
}
function HeaderCategories({
  onMouseLeave,
}: HeaderCategoriesProps): ReactElement {
  const { category } = useRecoilValue(categorys);
  return (
    <div css={categoryWrap}>
      <div css={dim} />
      <div className="content" onMouseLeave={onMouseLeave}>
        <SpaceLayout padding={[45, 68]}>
          <p className="title">CATEGORY</p>
          <ul>
            {category?.map((cate) => (
              <li key={`${cate.categorySeq}_${cate.categoryName}`}>
                <Button
                  className="header-category"
                  to={`/search?category=${cate.categorySeq}`}
                  type="link"
                  padding="0"
                  color={palette.gray6}
                  fontSize="14px"
                >
                  {cate.categoryName}
                </Button>
              </li>
            ))}
          </ul>
        </SpaceLayout>
      </div>
    </div>
  );
}

const categoryWrap = css`
  width: 100%;
  position: fixed;
  width: 100%;
  top: 136px;
  left: 0;
  z-index: 10;

  .content {
    background: ${palette.white};
    border-top: 1px solid ${palette.gray0};
    position: relative;
    z-index: 1;
    .title {
      font-size: 20px;
    }
    ul {
      display: flex;
      margin-top: 16px;
      flex-wrap: wrap;
      align-items: flex-start;

      li {
        display: flex;
        flex: 1;
        align-items: flex-start;
        min-width: 25%;
        max-width: 25%;
        padding: 12px 32px 12px 0;
      }
    }
  }
`;

const dim = css`
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(10px);
  position: absolute;
  top: 0;
  left: 0;
`;
export default HeaderCategories;
