import React, {
  forwardRef,
  ChangeEventHandler,
  KeyboardEventHandler,
} from 'react';

import SearchBarMobile from './SearchBarMobile';
import SearchBarPC from './SearchBarPC';

interface SearchBarProps {
  placeHolder?: string;
  inputValue?: string | string[];
  onFocus?: () => void;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  onKeyPress?: KeyboardEventHandler<HTMLInputElement>;
  clearInputValue?: () => void;
}

const SearchBar = forwardRef(
  ({ ...props }: SearchBarProps, ref?: React.Ref<HTMLInputElement>) => (
    <div>
      <SearchBarMobile {...props} ref={ref} />
      <SearchBarPC {...props} ref={ref} />
    </div>
  ),
);

export default SearchBar;
