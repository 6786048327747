import Link from 'next/link';

import React, { Fragment, ReactElement } from 'react';

import { useRecoilValue } from 'recoil';

import useOpenAppWithWebUrl from '@common/deepLinking/useOpenAppWithWebUrl';
import { setCloseTodayAppDownloadPopUp } from '@common/popUp';
import { css, SerializedStyles } from '@emotion/react';

import { palette } from '../../../lib/styles/palette';
import { CloseIcon } from './CloseIcon';

import { webviewRouteState } from '@recoils/webview/atom';

interface PopUpChildren {
  toggle: () => void;
  opacity: SerializedStyles;
}

function PopUpAppDownload({ toggle, opacity }: PopUpChildren): ReactElement {
  const isWebViesRouteState = useRecoilValue(webviewRouteState);
  const openAppWithWebUrl = useOpenAppWithWebUrl();
  // 오늘 하루 보지 않기
  const onClickDisabledToday = () => {
    setCloseTodayAppDownloadPopUp();
    toggle();
  };

  const handleOpenApp = () => {
    openAppWithWebUrl();
  };

  if (isWebViesRouteState) return <Fragment />;
  return (
    <div css={popUp}>
      <div css={infomation}>
        <div id="appinstall_msg" css={textWrap}>
          <p>
            중고나라 공식 앱에서
            <br />
            비슷한 상품 더 보기!
          </p>
          {/* <Link
            href={`${process.env.NEXT_PUBLIC_DEFAULT_URL}/event/detail/${
              process.env.NEXT_PUBLIC_ENV === 'live' ||
              process.env.NEXT_PUBLIC_ENV === 'stglive'
                ? '862'
                : '577'
            }`}
          >
            <a id="appinstall_details" onClick={toggle}>
              앱 혜택 자세히 보기
            </a>
          </Link> */}
        </div>
        {/* <img src={CiSquircleR16} alt="logo" /> */}
      </div>
      <button
        id="appinstall_open"
        type="button"
        aria-label="openApp"
        css={openBtn}
        onClick={handleOpenApp}
      >
        앱으로 보기
      </button>
      <button
        id="appinstall_close"
        type="button"
        aria-label="close"
        css={closeBtn}
        onClick={onClickDisabledToday}
      >
        오늘 하루 보지 않기
      </button>
      <div css={[dim, opacity]} />
    </div>
  );
}

const popUp = css`
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 234px;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  position: fixed;
  left: 0;
  z-index: 500;
  bottom: 0;

  padding: 24px 20px 20px;
`;

const infomation = css`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;

  img {
    width: 80px;
    height: 80px;
  }
`;

const textWrap = css`
  color: #fff;
  font-size: 18px;
  font-weight: 700;
  line-height: 23px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  p {
    text-align: center;
    margin-top: 8px;

    &:nth-of-type(2) {
      margin-top: 6px;
    }

    #appinstall_details {
      color: #a3a3a3;
      font-size: 12px;
      line-height: 14px;
    }
  }
  a {
    font-size: 16px;
    font-weight: 500;
    text-decoration: underline;
    margin-top: 8px;
  }
`;

const openBtn = css`
  width: 100%;
  margin-top: 24px;
  padding: 15px 0;
  color: #fff;
  font-size: 18px;
  font-weight: 700;
  border-radius: 4px;
  background-color: ${palette.orange9};
`;

const closeBtn = css`
  width: 100%;
  margin-top: 16px;
  color: #fff;
  font-size: 12px;
  background: none;
`;

const dim = css`
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: ${palette.black10};
  opacity: 0.9;
`;

export default PopUpAppDownload;
