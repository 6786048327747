import { ReactElement, ReactNode } from 'react';

import { TABLET_WIDTH } from '@common/constants/MEDIAS';
import { css } from '@emotion/react';
import { HeaderOptions } from '@type/web/header';

import { palette } from '@lib/styles/palette';

import Header from '@components/web/Header/Header';

import Footer from './Footer';

interface BasicLayoutProps {
  headerOptions?: HeaderOptions;
  children: ReactNode;
}

function BasicLayout({
  headerOptions,
  children,
}: BasicLayoutProps): ReactElement {
  const isFixed = headerOptions?.isFixed;
  const isTopPosition = headerOptions?.isTopPosition;

  return (
    <div css={wrapper}>
      {/* <TopBanner /> */}
      <div css={header(isFixed?.pc, isFixed?.tablet, isTopPosition)}>
        <Header {...headerOptions} />
      </div>
      <div className="container">{children}</div>
      <div className="footerWrap">
        <Footer />
      </div>
    </div>
  );
}

const wrapper = css`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: transparent; // 기본 스타일 제거
  position: relative;

  .container {
    flex: 1;
    width: 100%;
    background: #fff;
  }
  .footerWrap {
    width: 100%;
    align-self: flex-end;
    padding: 48px 15px;
    color: ${palette.gray5};
    background-color: ${palette.gray0};
    font-size: 12px;
  }
`;

const header = (
  pcIsFixed: boolean | undefined,
  tabletIsFixed: boolean | undefined,
  isTopPosition: boolean | undefined,
) => css`
  width: 100%;
  background: rgba(255, 255, 255, ${isTopPosition ? '0' : '1'});
  position: ${pcIsFixed ? 'fixed' : 'sticky'};
  top: 0;
  z-index: 99;

  @media (max-width: ${TABLET_WIDTH}) {
    position: ${tabletIsFixed ? 'fixed' : 'sticky'};
  }
`;

export default BasicLayout;
