/** userAgent의 앱 정보 체크 */
export const getOsTypeByUserAgent = () => {
  const { userAgent } = navigator;
  const index = userAgent.indexOf('JNAPP');

  if (index > -1) {
    const jnAppData = userAgent.slice(index).split(')')[0];
    const osTypeStr = jnAppData.split(';')[1].trim();

    return { os: osTypeStr };
  }
  return null;
};

export const getFlutterOsTypeByUserAgent = () => {
  const { userAgent } = navigator;
  const index = userAgent.indexOf('JNAPP');

  if (index > -1) {
    const jnAppData = userAgent.slice(index).split(')')[0];
    const osTypeStr = jnAppData.split(';')[2].trim();

    return { os: osTypeStr };
  }
  return null;
};

/** sessionStorage나 userAgent에서 저장된 앱 정보 가져옴
 *  @param isFindSession session에서 app Info 가져올 건지 여부
 */
export const getAppDataByStorage = (isFindSession = false) => {
  if (typeof navigator === 'undefined') return null;
  const { userAgent } = navigator;
  const userAgents = userAgent.split('|');

  if (userAgents[1] && !isFindSession) {
    try {
      const data = JSON.parse(userAgents[1]);
      return data;
    } catch (e) {
      return null;
    }
  }

  if (sessionStorage.getItem('jnAppInfo')) {
    const data = JSON.parse(sessionStorage.getItem('jnAppInfo') || '');

    return data;
  }

  return null;
};

/** Flutter 앱 여부 체크 */
export const isFlutterApp = () => {
  const osType = getFlutterOsTypeByUserAgent();

  if (osType === null) {
    return false;
  }
  if (osType !== null && osType.os === 'FLUTTER') {
    return true;
  }

  const data = getAppDataByStorage();
  return data !== null && data.scheme === 'jnapp';
};

/** 중고나라 AOS앱 여부 체크 */
export const isAndroidApp = () => {
  const osType = getOsTypeByUserAgent();

  if (osType !== null && osType.os === 'AOS') {
    return true;
  }

  const data = getAppDataByStorage();
  return data !== null && data.scheme === 'jnapp' && data['Os-Type'] === '0';
};

/** 중고나라 IOS앱 여부 체크 */
export const isIOSApp = () => {
  const osType = getOsTypeByUserAgent();

  if (osType !== null && osType.os === 'IOS') {
    return true;
  }

  const data = getAppDataByStorage();
  return data !== null && data.scheme === 'jnapp' && data['Os-Type'] === '1';
};

/** 중고나라 APP 체크 */
export const isAppDevice = () => {
  return isAndroidApp() || isIOSApp();
};

/** OS TYPE 체크 */
export const getOsType = (isNormal = true) => {
  if (isNormal) {
    if (isAndroidApp()) return 0;
    if (isIOSApp()) return 1;
    return 2;
  }
  if (isAndroidApp()) return 1;
  if (isIOSApp()) return 2;
  return 3;
};

export const saveSessionStorage = (name: string, data: any) => {
  window.sessionStorage.setItem(name, JSON.stringify(data));
};
export const removeSessionStorage = (name: string) => {
  window.sessionStorage.removeItem(name);
};
export const getSessionStorage = (name: string) => {
  const item = window.sessionStorage.getItem(name) || '';
  const data = JSON.parse(item);
  return data;
};
