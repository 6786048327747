export const mainList = {
  meta: {
    code: 0,
    message: 'SUCCESS',
  },
  data: {
    totalCount: 5722370,
    category: [
      {
        categorySeq: 1,
        parentSeq: 0,
        categoryName: '수입명품',
        categoryProductCount: 308594,
        childYn: 1,
      },
      {
        categorySeq: 2,
        parentSeq: 0,
        categoryName: '패션의류',
        categoryProductCount: 968100,
        childYn: 1,
      },
      {
        categorySeq: 3,
        parentSeq: 0,
        categoryName: '패션잡화',
        categoryProductCount: 548822,
        childYn: 1,
      },
      {
        categorySeq: 4,
        parentSeq: 0,
        categoryName: '뷰티',
        categoryProductCount: 213839,
        childYn: 1,
      },
      {
        categorySeq: 5,
        parentSeq: 0,
        categoryName: '출산/유아동',
        categoryProductCount: 460216,
        childYn: 1,
      },
      {
        categorySeq: 6,
        parentSeq: 0,
        categoryName: '모바일/태블릿',
        categoryProductCount: 581291,
        childYn: 1,
      },
      {
        categorySeq: 7,
        parentSeq: 0,
        categoryName: '가전제품',
        categoryProductCount: 384589,
        childYn: 1,
      },
      {
        categorySeq: 8,
        parentSeq: 0,
        categoryName: '노트북/데스크탑',
        categoryProductCount: 243014,
        childYn: 1,
      },
      {
        categorySeq: 9,
        parentSeq: 0,
        categoryName: '카메라/캠코더',
        categoryProductCount: 57909,
        childYn: 1,
      },
      {
        categorySeq: 10,
        parentSeq: 0,
        categoryName: '가구/인테리어',
        categoryProductCount: 165805,
        childYn: 1,
      },
      {
        categorySeq: 11,
        parentSeq: 0,
        categoryName: '리빙/생활',
        categoryProductCount: 308442,
        childYn: 1,
      },
      {
        categorySeq: 12,
        parentSeq: 0,
        categoryName: '게임',
        categoryProductCount: 116865,
        childYn: 1,
      },
      {
        categorySeq: 13,
        parentSeq: 0,
        categoryName: '반려동물/취미',
        categoryProductCount: 206154,
        childYn: 1,
      },
      {
        categorySeq: 14,
        parentSeq: 0,
        categoryName: '도서/음반/문구',
        categoryProductCount: 317607,
        childYn: 1,
      },
      {
        categorySeq: 15,
        parentSeq: 0,
        categoryName: '티켓/쿠폰',
        categoryProductCount: 287751,
        childYn: 1,
      },
      {
        categorySeq: 16,
        parentSeq: 0,
        categoryName: '스포츠',
        categoryProductCount: 286889,
        childYn: 1,
      },
      {
        categorySeq: 17,
        parentSeq: 0,
        categoryName: '레저/여행',
        categoryProductCount: 95209,
        childYn: 1,
      },
      {
        categorySeq: 1348,
        parentSeq: 0,
        categoryName: '중고차',
        categoryProductCount: 5189,
        childYn: 1,
      },
      {
        categorySeq: 19,
        parentSeq: 0,
        categoryName: '오토바이',
        categoryProductCount: 62658,
        childYn: 1,
      },
      {
        categorySeq: 20,
        parentSeq: 0,
        categoryName: '공구/산업용품',
        categoryProductCount: 74880,
        childYn: 1,
      },
      {
        categorySeq: 21,
        parentSeq: 0,
        categoryName: '무료나눔',
        categoryProductCount: 28432,
        childYn: 0,
      },
      {
        categorySeq: 1330,
        parentSeq: 0,
        categoryName: 'NFT',
        categoryProductCount: 115,
        childYn: 0,
      },
    ],
  },
};
