import { CoolDeal, VideoItems } from '@type/api/base/mainpage';
import { ApiResponse } from '@type/web/api';
import {
  ProductsData,
  PopularWordsData,
  TabData,
  AutoCompleteType,
  SearchProductResponse,
  SearchStoreResult,
  GetSearchPageAdsParamsType,
  SearchProductListResponse,
  StoreAutoCompleteRes,
  SearchStoreParam,
  SearchStoreRes,
} from '@type/web/search';
import {
  GetTotalProductByStorePropsType,
  StoreProductResponse,
} from '@type/web/store';

import { axiosRequest, AxiosDomain } from '@api/config';

const axiosSearch = AxiosDomain.search;
const axiosAdsSearch = AxiosDomain.searchAds;

/** 추천 상품 키워드 목록 */
export const getRecommendKeywords = (
  keyword: string,
): Promise<ApiResponse<AutoCompleteType>> => {
  const path = `/v25/search/autocomplete/keyword`;
  const body = {
    keyword,
    keywordCnt: 10,
  };
  return axiosRequest<AutoCompleteType>(axiosSearch, 'post', path, body);
};

/** 추천 가게 키워드 목록 */
export const getRecommendStoreKeywords = (
  keyword: string,
): Promise<ApiResponse<StoreAutoCompleteRes>> => {
  const path = `/v3/store/autocomplete?storeSearchKeyword=${keyword}&storeCnt=10`;
  return axiosRequest(axiosSearch, 'get', path);
};

export const getCoolProducts = (
  maxProductNum: number,
): Promise<ApiResponse<CoolDeal>> => {
  const path = `/v25/main/search/cool/product?maxProductNum=${maxProductNum}`;
  return axiosRequest<CoolDeal>(axiosSearch, 'get', path);
};

/** 비디오 상품 목록 */
export const getVideoProducts = (
  maxProductNum: number,
): Promise<ApiResponse<VideoItems>> => {
  const path = `/v25/main/search/video/product?maxProductNum=${maxProductNum}`;
  return axiosRequest<VideoItems>(axiosSearch, 'get', path);
};

/** 자주 찾는 검색어 목록 */
export const getPopularWords = (): Promise<ApiResponse<PopularWordsData>> => {
  const path = `/v25/search/main/popular/words`;
  return axiosRequest<PopularWordsData>(axiosSearch, 'get', path);
};

/** 자주 찾는 검색어의 상품 목록 */
export const getPopularProducts = (
  popularWord: string,
): Promise<ApiResponse<ProductsData>> => {
  const path = `/v25/search/main/popular/products`;
  const query = `?popularWord=${popularWord}`;
  return axiosRequest<ProductsData>(axiosSearch, 'get', path + query);
};

/** 검색화면 탭정보 호출 */
export const getSearchTabs = (): Promise<ApiResponse<TabData>> => {
  const path = `/v26/search/totaltab`;
  return axiosRequest<TabData>(axiosSearch, 'get', path);
};

/** 상품 검색 */
export const getSearchProducts = (
  param: any,
): Promise<ApiResponse<SearchProductResponse>> => {
  const path = `/v26/search/total`;
  return axiosRequest<SearchProductResponse>(axiosSearch, 'post', path, param);
};

/** 가게 검색 */
export const getSearchStores = (
  param: any,
): Promise<ApiResponse<SearchStoreResult>> => {
  const path = `/v25/search/store`;
  return axiosRequest<SearchStoreResult>(axiosSearch, 'post', path, param);
};

/** 카테고리 검색 (하위 버전) */
export const getSearchCategoryProducts = (
  param: any,
): Promise<ApiResponse<SearchProductResponse>> => {
  const path = `/v25/list/category`;
  return axiosRequest<SearchProductResponse>(axiosSearch, 'post', path, param);
};

/** 상품목록 검색 */
export const getSearchProductsList = (
  body: any,
): Promise<ApiResponse<SearchProductListResponse>> => {
  const path = `/v3/category/search`;
  const bodyData = {
    ...body,
    sort: body.sort && body.sort.toUpperCase(),
    saleYn: body.saleYn && body.saleYn.toUpperCase(),
    productFilterType:
      body.productFilterType && body.productFilterType.toUpperCase(),
    keywordSource: body.keywordSource && body.keywordSource.toUpperCase(),
  };
  return axiosRequest<SearchProductListResponse>(
    axiosSearch,
    'post',
    path,
    bodyData,
  );
};

/** 상품 검색: 상품 List (SEO Crawler 전용) */
export const getSearchProductsListSeo = (
  param: any,
): Promise<ApiResponse<SearchProductResponse>> => {
  const path = `/v25/search/product/crawler`;
  return axiosRequest<SearchProductResponse>(axiosSearch, 'post', path, param);
};

/** 카테고리 검색: 상품 List (SEO Crawler 전용) */
export const getSearchCategoryProductsSeo = (
  param: any,
): Promise<ApiResponse<SearchProductResponse>> => {
  const path = `/v25/list/category/crawler`;
  return axiosRequest<SearchProductResponse>(axiosSearch, 'post', path, param);
};

/* 스토어 전체 상품 검색 */
export const getTotalProductByStore = ({
  storeSeq,
  startIndex,
}: GetTotalProductByStorePropsType): Promise<
  ApiResponse<StoreProductResponse>
> => {
  const path = `/v2/search/store/${storeSeq}`;
  const body = {
    filter: { categoryDepth: 0 },
    searchQuantity: 30,
    sort: {
      price: 1,
    },
    startIndex,
  };

  return axiosRequest<any>(axiosSearch, 'post', path, body);
};

/** 인기 검색어 */
export const getPopularKeywords = (
  viewCount: string | number = 20,
): Promise<ApiResponse<PopularWordsData>> => {
  const path = `v3/main/popular/keyword`;
  const query = `?viewCount=${viewCount}`;
  return axiosRequest(axiosSearch, 'get', path + query);
};

/** 검색 페이지 광고 */
export const getSearchPageAds = (params: GetSearchPageAdsParamsType): any => {
  const path = `/v1/ad/product`;
  return axiosRequest(axiosAdsSearch, 'post', path, params, {
    referrer: `${window.location.href}`,
  });
};

/** 가게 검색 */
export const postSearchStore = (
  params: SearchStoreParam,
): Promise<ApiResponse<SearchStoreRes>> => {
  const path = `/v3/store/search`;
  return axiosRequest(axiosSearch, 'post', path, params);
};
