import { atom } from 'recoil';

export const popUpState = atom<boolean>({
  key: 'popUpState',
  default: false,
});

export const toastMessageState = atom<string | null>({
  key: 'toastMessage',
  default: null,
});

export default popUpState;
