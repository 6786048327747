import { DefaultSeo } from 'next-seo';
import type { AppProps } from 'next/app';
import router from 'next/router';
import Script from 'next/script';

import { useEffect, useState } from 'react';
import { isDesktop } from 'react-device-detect';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Hydrate } from 'react-query/hydration';

import { RecoilRoot } from 'recoil';

import { KAKAO_API_KEY } from '@common/const';
import { isCloseTodayAppDownloadPopUp } from '@common/popUp';

import { pageview } from '@lib/hook/gtm';
import useRouterHook from '@lib/hook/useRouterHook';
import GlobalStyles from '@lib/styles/GlobalStyles';

import useIsSSRLoading from '@hook/useIsSSRLoading';
import usePortal from '@hook/usePortal';

import {
  defaultAdditionalLinkTags,
  defaultAdditionalMetaTags,
  defaultSeoConfig,
} from '@config/next-seo.config';

import IconLoading from '@components/web/common/icons/IconLoading';

import 'antd/dist/antd.less';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

const App = ({ Component, pageProps }: AppProps) => {
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            retry: false,
            refetchOnWindowFocus: false,
          },
        },
      }),
  );
  const {
    PopUp: DownloadPopUp,
    onShowPopUp,
    onHidePopUp,
  } = usePortal('AppDownload');

  const hidePopUpPathname = [
    '/fraud',
    '/fraud/result',
    '/app-registration-banner',
    '/app-registration-form',
    '/appdownload',
    '/event/detail/862',
  ];

  useEffect(() => {
    const isCloseTodayDownloadPopupState = isCloseTodayAppDownloadPopUp();
    if (
      isDesktop ||
      (typeof window !== 'undefined' &&
        hidePopUpPathname.includes(window.location.pathname)) ||
      isCloseTodayDownloadPopupState
    ) {
      onHidePopUp();
    } else {
      onShowPopUp();
      if (typeof window !== 'undefined' && window.dataLayer !== undefined) {
        window.dataLayer.push({
          event: 'jn_appinstall_modal',
        });
      }
    }
  }, []);

  const isSSRLoading = useIsSSRLoading();

  useEffect(() => {
    router.events.on('routeChangeComplete', pageview);
    return () => {
      router.events.off('routeChangeComplete', pageview);
    };
  }, [router.events]);

  useEffect(() => {
    console.log(
      `%c 
      /$$$$$  /$$$$$$    /$$$$$$   /$$    /$$  /$$$$$$   /$$$$$$   /$$$$$$   /$$    /$$  /$$$$$$  /$$$$$$$   /$$$$$$ 
     |__  $$ /$$__  $$  /$$__  $$ | $$$  | $$ /$$__  $$ /$$__  $$ /$$__   $$| $$$  | $$ /$$__  $$| $$__  $$ /$$__  $$
        | $$| $$   \\ $$| $$  \\  $$| $$$$ | $$| $$  \\__/| $$  \\__/| $$  \\  $$| $$$$ | $$| $$  \\ $$| $$  \\ $$| $$  \\ $$
        | $$| $$   | $$| $$   | $$| $$  $$ $$| $$ /$$$$| $$ /$$$$| $$   | $$| $$ $$  $$| $$$$$$$$| $$$$$$$/| $$$$$$$$
  /$$   | $$| $$   | $$| $$   | $$| $$   $$$$| $$|_  $$| $$|_  $$| $$   | $$| $$   $$$$| $$__  $$| $$__  $$| $$__  $$
  | $$  | $$| $$   | $$| $$   | $$| $$  \\ $$$| $$  \\ $$| $$  \\ $$| $$   | $$| $$ \\  $$$| $$  | $$| $$  \\ $$| $$  | $$
  |  $$$$$$/|   $$$$$$/| $$$$$$ / | $$   \\ $$|  $$$$$$/|  $$$$$$/|  $$$$$$ /| $$  \\  $$| $$  | $$| $$  | $$| $$  | $$
   \\______/  \\______/   \\______/  |__/    \\__/ \\______/ \\______/  \\______/  |__/   \\__/|__/  |__/|__/  |__/|__/  |__/
                                                                                                    
                                                                                                    
                                                                                                    
  `,
      'color:orange',
      '평화로운 중고나라 지원하기 >>> https://jn-careers.notion.site/05e979832ee249989f3810af08271744',
    );
  }, []);

  return (
    <RecoilRoot>
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools initialIsOpen={false} />
        <Hydrate state={pageProps.dehydratedState}>
          <DefaultSeo
            {...defaultSeoConfig}
            additionalLinkTags={defaultAdditionalLinkTags}
            additionalMetaTags={defaultAdditionalMetaTags}
          />
          <Script
            id="gtag-base"
            strategy="afterInteractive"
            dangerouslySetInnerHTML={{
              __html: `
                  (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                  })(window,document,'script','dataLayer', 'GTM-MKMGLMH');
                `,
            }}
          />
          <Script
            id="airbridgeSDK"
            dangerouslySetInnerHTML={{
              __html: `(function (a_, i_, r_, _b, _r, _i, _d, _g, _e) {
                if (!a_[_b]) {
                  var d = { queue: [] };
                  _r.concat(_i).forEach(function (a) {
                    var i_ = a.split('.'),
                      a_ = i_.pop();
                    i_.reduce(function (a, i_) {
                      return (a[i_] = a[i_] || {});
                    }, d)[a_] = function () {
                      d.queue.push([a, arguments]);
                    };
                  });
                  a_[_b] = d;
                  a_ = i_.getElementsByTagName(r_)[0];
                  i_ = i_.createElement(r_);
                  i_.onerror = function () {
                    d.queue
                      .filter(function (a) {
                        return 0 <= _i.indexOf(a[0]);
                      })
                      .forEach(function (a) {
                        a = a[1];
                        a = a[a.length - 1];
                        'function' === typeof a && a('error occur when load airbridge');
                      });
                  };
                  i_.async = 1;
                  i_.src = '//static.airbridge.io/sdk/latest/airbridge.min.js';
                  a_.parentNode.insertBefore(i_, a_);
                }
              })(
                window,
                document,
                'script',
                'airbridge',
                'init fetchResource setBanner setDownload setDownloads openDeeplink setDeeplinks sendWeb setUserAgent setUserAlias addUserAlias setMobileAppData setUserId setUserEmail setUserPhone setUserAttributes clearUser setDeviceIFV setDeviceIFA setDeviceGAID events.send events.signIn events.signUp events.signOut events.purchased events.addedToCart events.productDetailsViewEvent events.homeViewEvent events.productListViewEvent events.searchResultViewEvent'.split(
                  ' ',
                ),
                ['events.wait'],
              );
        
              airbridge.init({
                app: 'joonggonara',
                webToken: '7fe9ec336fbc431d896ed7ed90512927',
                utmParsing: true,
                useProtectedAttributionWindow: true
              });`,
            }}
          />
          <Script
            strategy="afterInteractive"
            id="kakao-sdk"
            src="//developers.kakao.com/sdk/js/kakao.min.js"
            onLoad={() => window.Kakao.init(KAKAO_API_KEY)}
          />
          <GlobalStyles />
          {/*      
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore*/}
          {isSSRLoading ? <IconLoading /> : <Component {...pageProps} />}
          <DownloadPopUp />
        </Hydrate>
      </QueryClientProvider>
    </RecoilRoot>
  );
};

export default App;
