import axios, { AxiosInstance, AxiosRequestHeaders } from 'axios';

import { getToken } from '@common/auth';
import { getAppDataByStorage, isAppDevice } from '@common/webview';
import {
  OsTypeKey,
  ContentTypeKeys,
  ApiHeaders,
  ApiResponse,
} from '@type/web/api';

export const ContentType = {
  JSON_TYPE: 'application/json',
  FORM_TYPE: 'application/x-www-form-urlencoded',
  MULTIPART_TYPE: 'multipart/form-data',
};

export function getHeaders(
  type = 'JSON_TYPE',
): AxiosRequestHeaders | undefined | any {
  let contentType = ContentType.JSON_TYPE;
  if (type === 'FORM') contentType = ContentType.FORM_TYPE;
  if (type === 'MULTIPART') contentType = ContentType.MULTIPART_TYPE;

  const token = getToken();

  const userAgentData = getAppDataByStorage(true) || {};
  // 웹뷰일 경우 uuid 값 세팅. userAgentData.uuid = undifined || '' || string
  const uuid =
    userAgentData.uuid && isAppDevice()
      ? { device_uuid: userAgentData.uuid }
      : {};

  const headers = token
    ? {
        // 'Authorization': `Bearer ${token}`,
        'Content-Type': contentType,
        'Os-Type': OsTypeKey.WEB,
        'App-Version': userAgentData['App-Version'] || null,
        ...uuid,
      }
    : {
        'Content-Type': contentType,
        'Os-Type': OsTypeKey.WEB,
        'App-Version': userAgentData['App-Version'] || null,
        ...uuid,
      };

  return headers;
}

export const createAxiosInstance = (
  baseURL: string,
  headerType: ContentTypeKeys = 'JSON_TYPE',
): AxiosInstance => {
  return axios.create({
    baseURL,
    headers: getHeaders(headerType),
  });
};
export const createMediaAxiosInstance = (
  baseURL: string,
  // headerType: ContentTypeKeys = 'JSON_TYPE',
): AxiosInstance => {
  return axios.create({
    baseURL,
    headers: {
      'Content-Type': 'image/*',
    },
  });
};

const baseAxios = createAxiosInstance(
  `${process.env.NEXT_PUBLIC_API_BASE_URL}`,
);
const mainBaseAxios = createAxiosInstance(
  `${process.env.NEXT_PUBLIC_MAIN_API_BASE_URL}`,
);
const bootAxios = createAxiosInstance(
  `${process.env.NEXT_PUBLIC_BOOT_API_URL}`,
);
const edgeAxios = createAxiosInstance(
  `${process.env.NEXT_PUBLIC_EDGE_API_URL}`,
);
const pointEventAxios = createAxiosInstance(
  `${process.env.NEXT_PUBLIC_POINT_API_URL}`,
);
const searchAxios = createAxiosInstance(
  `${process.env.NEXT_PUBLIC_SEARCH_API_URL}`,
);
const searchAdsAxios = createAxiosInstance(
  `${process.env.NEXT_PUBLIC_SEARCH_ADS_API_URL}`,
);
const standardAxios = createAxiosInstance(
  `${process.env.NEXT_PUBLIC_STANDARD_PRICE_API_URL}`,
);
const wikiSearchAxios = createAxiosInstance(
  `${process.env.NEXT_PUBLIC_WIKI_SEARCH_API_URL}`,
);

export const AxiosDomain = {
  base: baseAxios,
  mainBase: mainBaseAxios,
  boot: bootAxios,
  edge: edgeAxios,
  pointEvent: pointEventAxios,
  search: searchAxios,
  searchAds: searchAdsAxios,
  standard: standardAxios,
  wikiSearch: wikiSearchAxios,
};

type Method = 'get' | 'post' | 'put' | 'delete';
export const axiosRequest = async <T>(
  axiosInstance: AxiosInstance,
  method: Method = 'get',
  url: string,
  data?: any,
  headers?: any,
  params?: any,
  validStatusList: number[] = [],
): Promise<ApiResponse<T>> => {
  const token = getToken();

  const instance = await axiosInstance.request<ApiResponse<T>>({
    method,
    url,
    ...(data && { data }),
    ...{
      headers: token
        ? {
            ...getHeaders(),
            ...headers,
          }
        : {
            ...headers,
          },
    },
    ...(params && { params }),

    /* https://axios-http.com/kr/docs/req_config
     200 번대가 아닌 다른 HTTP 응답 상태에서도 응답으로 받은 값(data, message 등)을 사용해야 하는 경우
     Promise 가 거부되지 않고 이행되어야 응답을 꺼내서 사용할 수 있습니다.
     props인 validStatusList에 허락할 HTTP 응답 상태를 담아 전달하면 됩니다.
    */
    validateStatus: (status) => {
      return (
        (status >= 200 && status < 300) ||
        !!validStatusList.find((validStatus) => validStatus === status)
      );
    },
  });

  return instance.data;
};
