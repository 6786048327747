import Link from 'next/link';

import React, {
  ReactElement,
  ReactNode,
  useCallback,
  useState,
  useMemo,
} from 'react';

import { Tabs } from 'antd';
import dayjs from 'dayjs';

import { TABLET_WIDTH } from '@common/constants/MEDIAS';
import { changeOmitText } from '@common/parser';
import { css } from '@emotion/react';
import { PopularWordItem } from '@type/api/base/mainpage';

import { palette } from '@lib/styles/palette';

const { TabPane } = Tabs;

interface RankKeywordDetailTabProps {
  keywordList: PopularWordItem[];
  reloadDateTime: string;
  setIsShowDetail: (
    cbOrIsShow: ((isShow: boolean) => boolean) | boolean,
  ) => void;
}
function RankKeywordDetailTab({
  keywordList,
  reloadDateTime,
  setIsShowDetail,
}: RankKeywordDetailTabProps): ReactElement {
  const [activeTab, setActiveTab] = useState('1 - 10위');

  // 실시간 검색 리스트 그룹화 처리
  const keywordListGroup = useMemo((): { [key: string]: PopularWordItem[] } => {
    const firstList = keywordList.slice(0, 10);
    const secondList = keywordList.slice(10, 20);
    // const maxLength = 10;
    let result = {};
    for (let i = 0; i < firstList.length; i += firstList.length) {
      const addList = firstList.slice(i, i + firstList.length);
      result = {
        ...result,
        [`${i + 1} - ${(i += firstList.length)}위`]: addList,
      };
    }
    if (secondList.length) {
      for (let i = 0; i < secondList.length; i += secondList.length) {
        const addList = secondList.slice(i, i + secondList.length);
        result = {
          ...result,
          [`${i + 11} - ${(i += secondList.length + 10)}위`]: addList,
        };
      }
    }
    // for (let i = 0; i < list.length; i += maxLength) {
    //   const addList = list.slice(i, i + maxLength);
    //   result[`${i + 1} - ${(i += maxLength)}위`] = addList;
    // }

    // for (let i = 0; i < keywordList.length; i ++) {

    //   }
    return result;
  }, [keywordList]);

  // 실시간 검색어 탭 동작
  const onChangeTab = useCallback(
    (key) => {
      setActiveTab(key);
    },
    [activeTab],
  );

  return (
    <div
      css={rankDetail}
      data-area="keywordArea"
      onMouseLeave={() => setIsShowDetail(false)}
    >
      <p className="title">실시간 검색어</p>
      <Tabs onChange={onChangeTab}>
        {Object.keys(keywordListGroup)?.map(
          (key: string, groupIndex: number) => {
            return (
              <TabPane tab={key} key={key}>
                {keywordListGroup[key]?.map(
                  (keyword: PopularWordItem, i: number): ReactNode => (
                    <Link
                      href={`/search/${encodeURIComponent(
                        keyword.popularWord,
                      )}`}
                      key={`${keyword.popularWord}_${keyword.popularWordRegDate}`}
                      passHref
                    >
                      <a className="keyword">
                        <span css={numbering(true)}>
                          {groupIndex === 0 ? i + 1 : i + 11}
                        </span>
                        {changeOmitText(15, keyword.popularWord)}
                      </a>
                    </Link>
                  ),
                )}
              </TabPane>
            );
          },
        )}
      </Tabs>
      <p className="dateTime">
        {dayjs(reloadDateTime).format('YYYY.MM.DD HH:mm')} 기준
      </p>
    </div>
  );
}

const rankDetail = css`
  width: 100%;
  padding: 20px;
  font-weight: 400;
  background: ${palette.white};
  border: 1px solid ${palette.gray2};
  border-radius: 8px;
  opacity: 1;
  transform: translateY(-5%);
  transition: all 0.15s ease-out;
  z-index: 101;

  &:hover {
    opacity: 1;
    transform: translateY(0);
  }
  .title {
    font-size: 16px;

    line-height: 20px;
  }
  .keyword {
    display: block;
    padding: 6px 0;
    line-height: 18px;
    color: ${palette.black10};
    font-size: 14px;
    span {
      display: inline-block;
      width: 24px;

      color: ${palette.orange9};
    }
  }

  .dateTime {
    padding-top: 16px;
    text-align: right;
    font-size: 12px;
    color: ${palette.gray5};
    border-top: 1px solid ${palette.gray2};
  }

  .ant-tabs-nav {
    margin: 16px 0 0 0;
    .ant-tabs-nav-wrap {
      &:before {
        display: none;
      }
      .ant-tabs-nav-list {
        flex: 1;
        height: 40px;
        border: 1px solid ${palette.gray2};
        border-radius: 4px;
        .ant-tabs-tab {
          display: flex;
          flex: 1;
          margin: 0;
          color: ${palette.gray5};
          text-align: center;
          &:not(:first-of-type) {
            &:before {
              content: '';
              width: 1px;
              height: 16px;
              margin: auto;
              background: ${palette.gray2};
              position: absolute;
              top: 0;
              bottom: 0;
              left: 0;
            }
          }
          > div {
            flex: 1;
          }
          &.ant-tabs-tab-active {
            > div {
              color: ${palette.black10};
            }
          }
        }
        .ant-tabs-ink-bar {
          display: none;
        }
      }
    }
  }
  .ant-tabs-tabpane {
    padding: 10px 0;
  }

  @media (max-width: ${TABLET_WIDTH}) {
    display: none;
  }
`;

const numbering = (isTopRank: boolean) => css`
  color: ${isTopRank ? palette.orange9 : palette.black10};
`;
export default RankKeywordDetailTab;
