import React, { ReactElement } from 'react';

import { css } from '@emotion/react';

import * as icons from './svg';

export type IconType = keyof typeof icons;
export const iconTypes: IconType[] = Object.keys(icons) as IconType[];

export interface IconProps {
  icon: IconType;
  color?: string;
  stroke?: string;
  size?: string | number;
  rotate?: number;
  className?: string;
  onClick?: () => void;
}

const NORMAL_STROKE_COLOR = '#0A0400';

function Icon({
  icon,
  color, // 안씀
  stroke, // Line Color
  size,
  rotate,
  className,
  onClick,
}: IconProps): ReactElement {
  const SVGIcon = icons[icon];
  const isFill = /Fill/.test(icon);

  const strokeColor = stroke || (isFill ? NORMAL_STROKE_COLOR : '');
  const widthPx =
    size &&
    (typeof size === 'number' ? `${size}px` : `${size.replace('px', '')}px`);

  return (
    <SVGIcon
      onClick={onClick}
      css={svgStyle(color, strokeColor, rotate, widthPx)}
      className={className}
    />
  );
}

export default Icon;

const svgStyle = (
  color?: string,
  strokeColor?: string,
  rotate?: number,
  widthPx?: string | 0,
) => css`
  color: ${strokeColor} !important;
  ${widthPx !== undefined && `width: ${widthPx}`};
  height: auto;
  transform: rotate(${rotate || 0}deg);

  path {
    fill: ${color} !important;
  }
`;
