import { MOBILE_WIDTH, TABLET_WIDTH } from '@common/constants/MEDIAS';
import styled from '@emotion/styled';

export const MobileTemplate = styled.div`
  display: none;

  @media (max-width: ${MOBILE_WIDTH}) {
    display: block;
  }
`;

export const TabletTemplate = styled.div`
  display: none;

  @media (max-width: ${TABLET_WIDTH}) {
    display: block;
  }

  @media (max-width: ${MOBILE_WIDTH}) {
    display: none;
  }
`;

export const PcTemplate = styled.div`
  @media (max-width: ${TABLET_WIDTH}) {
    display: none;
  }
`;
