import { useRouter } from 'next/router';

import React, { ReactElement, createFactory } from 'react';

import { Spin } from 'antd';

import { css } from '@emotion/react';

import { palette } from '@lib/styles/palette';

import BaisicLayout from '@components/web/Layout/BaisicLayout';
import CenterLayout from '@components/web/Layout/CenterLayout';
import SpaceLayout from '@components/web/Layout/SpaceLayout';

interface LoadingType {
  type?: 'BasicLayout' | 'CenterLayout' | 'SpaceLayout';
  hasFooter?: boolean;
}

function IconLoading({ type }: LoadingType): ReactElement {
  const router = useRouter();

  const basicLayoutPages = [
    /^\/$/,
    /^\/product\/detail/,
    /^\/product\/list/,
    /^\/event\/list/,
  ];

  const { pathname } = router;

  const useSpaceLayout = basicLayoutPages?.every((reg) => !reg?.test(pathname));

  const LayoutComponentMap = {
    BasicLayout: BaisicLayout,
    CenterLayout,
    SpaceLayout,
  };

  if (!type) type = useSpaceLayout ? 'SpaceLayout' : 'BasicLayout';
  const LayoutComponent = LayoutComponentMap[type];

  return (
    <LayoutComponent>
      <Spin tip="Loading" size="large" css={loading} />
    </LayoutComponent>
  );
}

const loading = css`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 200px 0;

  .ant-spin-dot-item {
    background-color: ${palette.orange9};
  }
`;

export default IconLoading;
