import Link from 'next/link';

import { ReactElement, useState } from 'react';

import { FOOTER_OFFICIAL_SNS } from '@common/const';
import {
  MOBILE_MEDIA,
  MOBILE_WIDTH,
  TABLET_MEDIA,
} from '@common/constants/MEDIAS';
import categoryList from '@data/categoryList.json';
import footerData from '@data/footer.json';
import { css } from '@emotion/react';

import { palette } from '@lib/styles/palette';
import sizeType from '@lib/styles/sizeType';

import useBoolean from '@hook/useBoolean';

import Button from '@components/common/Button/Button';
import Icon from '@components/common/Icon/Icon';

function Footer(): ReactElement {
  const [showCategories, setShowCategories] = useState(false);
  const { companyAdditionalTitle, companyAdditional, terms, responsibility } =
    footerData;

  const companyAdditionalWithSpan = companyAdditional.replace(
    '|',
    '<span class="divider"></span>',
  );

  const { value: isShow, toggle: onToggle } = useBoolean(false);

  const onClickToggleMoreFooter = () => {
    onToggle();
  };
  const toggleCategories = () => {
    setShowCategories(!showCategories);
  };

  return (
    <footer css={footer(isShow)}>
      <div className="title">
        <strong>{companyAdditionalTitle}</strong>
        <button
          type="button"
          aria-label="toggleFooter"
          onClick={onClickToggleMoreFooter}
        >
          {isShow ? (
            <Icon icon="arrowUp" size={12} stroke={palette.gray7} />
          ) : (
            <Icon icon="arrowDown" size={12} stroke={palette.gray7} />
          )}
        </button>
      </div>
      {companyAdditional && (
        <div
          className="content"
          dangerouslySetInnerHTML={{ __html: companyAdditionalWithSpan }}
        />
      )}
      <div css={categoryListStyles(showCategories)}>
        <div className="categoryToggle">
          <strong>카테고리 목록</strong>
          <button
            type="button"
            aria-label="toggleCategories"
            onClick={toggleCategories}
          >
            {showCategories ? (
              <Icon icon="arrowUp" size={12} stroke={palette.gray7} />
            ) : (
              <Icon icon="arrowDown" size={12} stroke={palette.gray7} />
            )}
          </button>
        </div>
        <div className="categoryListLinks">
          <ul>
            <li>
              <Link href={`/category`} passHref>
                <a>전체</a>
              </Link>
            </li>
            {Object.values(categoryList).map((category) => {
              if (category.level === 1) {
                return (
                  <li key={category.seq}>
                    <Link href={`/category${category.path}`} passHref>
                      <a>{category.name}</a>
                    </Link>
                  </li>
                );
              }
            })}
          </ul>
        </div>
      </div>
      <ul css={term}>
        {terms.map(({ type, url, name }) => (
          <li key={type}>
            <a
              href={type === 'member' ? '/member-dispute' : url}
              style={{
                fontWeight: `${name === '개인정보처리방침' && '600'}`,
              }}
              target="_blank"
              rel="noreferrer"
            >
              {name}
            </a>
            <div className="divider" />
          </li>
        ))}
      </ul>
      <div css={officialLink}>
        {FOOTER_OFFICIAL_SNS?.map((sns) => {
          const { icon, url } = sns;
          return (
            <Button
              key={icon}
              type="link"
              padding="0"
              onClick={() => window.open(url)}
              aria-label={icon}
            >
              <Icon icon={icon} size={20} stroke="none" />
            </Button>
          );
        })}
      </div>
      <div css={responsibility}>{responsibility}</div>
    </footer>
  );
}

const footer = (isShow: boolean) => css`
  max-width: ${sizeType.sizeLayout.large};
  width: 100%;
  margin: 0 auto;
  line-height: 1.5;
  white-space: pre-line;
  position: relative;

  .title {
    display: flex;
    align-items: center;

    strong {
      font-weight: 700;
      color: ${palette.gray6};
      margin-right: 6px;
    }

    button {
      display: none;

      @media screen and (max-width: 1023px) {
        display: flex;
        align-items: center;
      }
    }
  }

  .content {
    line-height: 2;
    margin: 20px 0 33px 0;
    @media screen and (max-width: 1023px) {
      display: ${isShow ? 'block' : 'none'};
    }
  }

  .responsibility {
    margin-top: 20px;
  }

  .divider {
    width: 1px;
    height: 8px;
    background-color: ${palette.gray2};
    margin: 0 8px;
    display: inline-block;
  }
`;

const term = css`
  display: flex;
  flex-wrap: wrap;
  margin-top: 33px;

  li {
    margin-bottom: 10px;
    display: flex;
    align-items: baseline;
    line-height: normal;

    a {
      color: ${palette.gray6};
    }

    &:last-of-type {
      padding-right: 0;
      border: none;

      .divider {
        background-color: transparent;
      }
    }
  }

  @media (max-width: ${MOBILE_WIDTH}) {
    margin-top: 12px;
  }
`;

const categoryListStyles = (showList: boolean) => css`
  .categoryToggle {
    display: flex;
    align-items: center;
    padding: 0 0 8px 0;

    ${MOBILE_MEDIA} {
      padding: 16px 0 8px 0;
    }
    ${TABLET_MEDIA} {
      padding: 16px 0 8px 0;
    }
    strong {
      font-weight: 700;
      color: ${palette.gray6};
      margin-right: 6px;
    }

    button {
      display: flex;
      align-items: center;
    }
  }
  .categoryListLinks {
    display: ${showList ? 'block' : 'none'};
    li {
      padding: 4px;
    }
    a {
      color: ${palette.gray6};
    }
  }
`;

const officialLink = css`
  display: flex;
  position: absolute;
  top: 0;
  right: 0;

  @media (max-width: ${MOBILE_WIDTH}) {
    margin-top: 12px;
    margin-bottom: 12px;
    position: static;
  }

  button {
    margin-left: 20px;
    &:first-of-type {
      margin-left: 0;
    }
  }
`;

export default Footer;
