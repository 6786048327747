import crypto from 'crypto';

import { APP_USE_LOGIN_TEST } from '@common/const';
import { TokenInfo } from '@type/web/token';

import { removeSessionStorage } from './webview';

/**
 * 개발환경 로그인 허용 확인
 */
export const useLocalhostLogin = (): boolean => {
  return APP_USE_LOGIN_TEST === 'true';
};

/** localStorage에 저장된 토큰정보 추출 */
export const getToken = (): string | null => {
  return typeof window !== 'undefined'
    ? localStorage.getItem('jn_access_token')
    : null;
};

export const removeToken = () => {
  localStorage.removeItem('jn_access_token');
  localStorage.removeItem('jn_login_info');
  removeSessionStorage('jnAppInfo');
};

export const convertToken = (token = getToken()) => {
  try {
    if (token) {
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace('-', '+').replace('_', '/');
      const res: TokenInfo = JSON.parse(window.atob(base64));
      return res;
    }
    removeToken();
    return null;
  } catch (e) {
    return null;
  }
};

/** localStorage에 토큰정보 저장 */
export const saveToken = (token: string) => {
  localStorage.setItem('jn_access_token', token);

  const info = convertToken(token);
  if (info !== null) {
    if (info.nickName) {
      info.nickName = decodeURIComponent(info.nickName);
    }
    if (info.profileImgUrl) {
      info.profileImgUrl = decodeURIComponent(info.profileImgUrl);
    }

    const infoJSON = JSON.stringify(info);
    localStorage.setItem('jn_login_info', infoJSON);
  }
};

/** 비밀번호 암호화 */
export const encPassword = (password: string) => {
  const key = `${process.env.REACT_APP_PUB_KEY}`;
  const encryptPassword = crypto
    .publicEncrypt(
      { key, padding: crypto.constants.RSA_PKCS1_PADDING },
      Buffer.from(password, 'utf8'),
    )
    .toString('base64');
  return encryptPassword;
};
