import Link from 'next/link';
import { useRouter } from 'next/router';

import React, {
  ReactElement,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import useEmblaCarousel from 'embla-carousel-react';

import { MOBILE_WIDTH, TABLET_WIDTH } from '@common/constants/MEDIAS';
import { css } from '@emotion/react';

import { palette } from '@lib/styles/palette';

import useRecentSearches from '@hook/useRecentSearches';

import Button from '@components/common/Button/Button';
import Icon from '@components/common/Icon/Icon';
import SearchBar from '@components/common/SearchBar';
import SpaceLayout from '@components/web/Layout/SpaceLayout';
import usePopUpSearch from '@components/web/popup/hook/usePopUpSearch';

interface PopUpSearchProps {
  onClosePopUp: () => void;
}
type Tab = 'product' | 'store';

function PopUpSearch({ onClosePopUp }: PopUpSearchProps): ReactElement {
  const router = useRouter();
  const [tab, setTab] = useState<Tab>('product');
  const {
    data: { inputValue, autoCompleteList, category },
    onClearInputValue,
    onChangeInputValue,
    onSearchKeyword,
  } = usePopUpSearch(tab);

  const { recentSearches, clearRecentSearches } = useRecentSearches();

  const searchBar = useRef<HTMLInputElement>(null);

  const [emblaRef, emblaApi] = useEmblaCarousel({
    align: 'start',
    dragFree: true,
    containScroll: 'trimSnaps',
  });

  const onClickRecentKeyword = useCallback(
    (e) => {
      if (!emblaApi || !emblaApi.clickAllowed()) {
        e.preventDefault();
      } else {
        onClosePopUp();
      }
    },
    [emblaApi],
  );
  const onClickRecentSearchKeyword = (keyword: string) => {
    // 카테고리 필터 적용 되어있을때 함께 적용 :
    // if (router.query.category) {
    //   return `/search/${encodeURIComponent(keyword)}?category=${
    //     router.query.category
    //   }`;
    // }
    return `/search/${encodeURIComponent(keyword)}`;
  };
  useEffect(() => {
    if (searchBar.current) {
      searchBar.current.focus();
    }
  }, []);

  return (
    <SpaceLayout>
      <SearchBar
        ref={searchBar}
        inputValue={inputValue}
        onKeyPress={onSearchKeyword}
        onChange={onChangeInputValue}
        clearInputValue={onClearInputValue}
      />
      {inputValue ? (
        <>
          <div css={tabStyle}>
            <button
              className={tab === 'product' ? 'tab' : ''}
              onClick={() => setTab('product')}
            >
              상품
            </button>
            <button
              className={tab === 'store' ? 'tab' : ''}
              onClick={() => setTab('store')}
            >
              가게
            </button>
          </div>
          {tab === 'product' && (
            /* 상품 검색어 추천 리스트 */
            <ul css={autoCompleteWrap}>
              {autoCompleteList.map((item, i) => (
                <li key={`${item}${`_${i}`}`}>
                  <Link href={`/search/${encodeURIComponent(item)}`}>
                    <a className="link">
                      {item}
                      <Icon icon="arrow_up_left" stroke={palette.gray4} />
                    </a>
                  </Link>
                </li>
              ))}
            </ul>
          )}
          {tab === 'store' && (
            /* 가게 검색어 추천 리스트 */
            <ul css={autoCompleteWrap}>
              {autoCompleteList.map((item, i) => (
                <li key={`${item}${`_${i}`}`}>
                  <Link
                    href={`/search-store?keyword=${encodeURIComponent(item)}`}
                  >
                    <a className="link">
                      {item}
                      <Icon icon="arrow_up_left" stroke={palette.gray4} />
                    </a>
                  </Link>
                </li>
              ))}
            </ul>
          )}
        </>
      ) : (
        <>
          <div css={articleWrap}>
            <p className="title">
              최근 검색어
              {!!recentSearches.length && (
                <Button
                  type="link"
                  fontSize="0.8rem"
                  color={palette.gray6}
                  padding="0"
                  onClick={clearRecentSearches}
                >
                  모두 지우기
                </Button>
              )}
            </p>

            {recentSearches.length ? (
              <div className="recent" ref={emblaRef}>
                <ul className="list">
                  {recentSearches.map((keyword: string) => (
                    <li css={recentItem} key={keyword}>
                      <Button
                        size="small"
                        color={palette.gray6}
                        borderColor={palette.gray0}
                        backgroundColor={palette.gray0}
                        shape="round"
                        to={onClickRecentSearchKeyword(keyword)}
                        onClick={onClosePopUp}
                      >
                        {keyword.length > 8
                          ? `${keyword.slice(0, 8)}⋯`
                          : keyword}
                      </Button>
                    </li>
                  ))}
                </ul>
              </div>
            ) : (
              <p className="noList">최근 검색어가 없습니다.</p>
            )}
          </div>
          <div css={[articleWrap, tabletOnlyStyle]}>
            <p className="title">카테고리</p>
            <ul className="list">
              {category.map((item) => (
                <li key={item.categoryName} css={categoryItem}>
                  <Button
                    className="header-category"
                    type="light"
                    size="small"
                    to={`/search?category=${item.categorySeq}`}
                    onClick={onClosePopUp}
                  >
                    {item.categoryName}
                  </Button>
                </li>
              ))}
            </ul>
          </div>
        </>
      )}
    </SpaceLayout>
  );
}

const articleWrap = css`
  padding: 40px 0 70px 0;
  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 20px;
    font-weight: 500;
  }

  .list {
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
    gap: 8px;
  }

  .noList {
    margin-top: 30px;
    text-align: center;
    color: ${palette.gray5};
    font-size: 14px;
  }

  @media (max-width: ${TABLET_WIDTH}) {
    padding: 20px 0 30px 0;
  }

  @media (max-width: ${MOBILE_WIDTH}) {
    .list li {
      flex-shrink: 0;
    }

    .recent {
      overflow-x: hidden;

      .list {
        flex-wrap: nowrap;
      }
    }
  }
`;

const recentItem = css`
  a:hover {
    color: ${palette.gray6};
    border-color: ${palette.gray0};
    background-color: ${palette.gray0};
  }
`;

const categoryItem = css`
  a:hover {
    color: ${palette.black10};
    border-color: ${palette.gray2};
    background-color: ${palette.gray1};
  }
`;

const autoCompleteWrap = css`
  margin-top: 16px;
  min-height: 104px;
  li {
    &:not(:last-child) {
      border-bottom: 1px solid ${palette.gray0};
    }

    .link {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 52px;
      color: ${palette.black10};
    }
  }
`;

const tabletOnlyStyle = css`
  display: none;

  @media (max-width: ${TABLET_WIDTH}) {
    display: block;
  }
`;

const tabStyle = css`
  margin-top: 1em;
  width: 100%;
  display: flex;
  justify-content: space-between;
  text-align: center;
  color: ${palette.gray4};

  button {
    width: 50%;
    background-color: transparent;
    height: 3em;
    position: relative;
    box-sizing: content-box;
    border-bottom: 2px solid ${palette.gray4};
  }

  .tab {
    color: black;
    border-bottom: 2px solid black;
  }
`;

export default PopUpSearch;
