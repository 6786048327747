import React, { ReactElement, ReactNode } from 'react';

import { css, SerializedStyles } from '@emotion/react';

import useKeyDown from '@hook/useKeyDown';
import useResponsive from '@hook/useResponsive';

import Button from '@components/common/Button/Button';
import Icon from '@components/common/Icon/Icon';
import SpaceLayout from '@components/web/Layout/SpaceLayout';

import { palette } from '../../../lib/styles/palette';

interface PopUpChildren {
  onHide: () => void;
  backgroundAlpha?: number;
  children: ReactNode;
  opacity: SerializedStyles;
}

function PopUpFullPage({
  onHide,
  backgroundAlpha = 1,
  children,
  opacity,
}: PopUpChildren): ReactElement {
  const { isPC } = useResponsive();
  useKeyDown('Escape', onHide);

  return (
    <div css={popUp}>
      <div css={[content(backgroundAlpha), !isPC && fullHeight]}>
        <SpaceLayout>
          <div css={header}>
            <Button type="link" onClick={onHide} padding="0">
              <Icon icon="close" stroke={palette.black10} />
            </Button>
          </div>
        </SpaceLayout>
        {children}
      </div>
      <div css={[dim, opacity]} onClick={onHide} />
    </div>
  );
}

const popUp = css`
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100%;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  position: fixed;
  left: 0;
  z-index: 500;
  bottom: 0;
`;

const content = (backgroundAlpha: number) => css`
  width: 100%;
  background-color: rgba(255, 255, 255, ${backgroundAlpha});
`;

const header = css`
  display: flex;
  justify-content: end;
  height: 48px;
  margin-top: 20px;
  padding: 16px 0;
`;

const fullHeight = css`
  min-height: 100vh;
  overflow-y: scroll;
`;

const dim = css`
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(10px);
`;

export default PopUpFullPage;
