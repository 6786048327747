import React, { ReactElement } from 'react';

import { HeaderDefaultOptions, PrimaryResponseType } from '@type/web/header';

import HeaderPrimaryMobileTablet from './HeaderPrimaryMobileTemplete';
import HeaderPrimaryPC from './HeaderPrimaryPCTemplete';

type HeaderPrimaryProps = HeaderDefaultOptions & PrimaryResponseType;

function HeaderPrimary(props: HeaderPrimaryProps): ReactElement {
  return (
    <>
      <HeaderPrimaryMobileTablet {...props} />
      <HeaderPrimaryPC />
    </>
  );
}

export default HeaderPrimary;
