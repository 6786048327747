import { atom, selector } from 'recoil';

import {
  SearchFilterData,
  SearchProductsListFilter,
  SearchTab,
} from '@type/web/search';

export const initialSearchFilterData: SearchFilterData = {
  // 카테고리 필터
  categoryPath: [],
  categoryDepth: 0,
  categorySeq: 0,

  // 지역 필터
  locations: null,

  // 가격 필터
  priceRange: null,

  // 상품등록 구분 필터
  productFilter: 'ALL',

  // 정렬 필터
  sort: 'RECENT_SORT',
};

export const searchFilterData = atom<SearchFilterData>({
  key: 'SEARCH/SEARCH_FILTER_DATA',
  default: {
    ...initialSearchFilterData,
  },
});

export const initialSearchFilterDataV3: SearchProductsListFilter = {
  categoryPath: [],
  locationName: '',
  categoryFilter: [
    {
      categoryDepth: 0,
      categorySeq: 0,
    },
  ],
  priceFilter: {
    maxPrice: 2000000000,
    minPrice: 0,
  },
  productFilterType: 'ALL',
  sort: 'RECENT_SORT',
};

export const searchFilterDataV3 = atom<SearchProductsListFilter>({
  key: 'SEARCH/SEARCH_FILTER_DATAV3',
  default: {
    ...initialSearchFilterDataV3,
  },
});

interface SearchTabAtom extends SearchTab {
  activeTab: string;
  viewType: boolean;
}

export const searchTabData = atom<SearchTabAtom>({
  key: 'SEARCH/SEARCH_TAB_DATA',
  default: {
    activeTab: 'product',
    searchTab: null,
    viewType: false,
  },
});

interface MobileFilterSave {
  category?: string;
  lon?: string;
  lat?: string;
  minPrice?: string;
  maxPrice?: string;
}

export const mobileFilterSave = atom<Record<string, string>>({
  key: 'SEARCH/MOBILE_FILTER_SAVE',
  default: {},
});

export type RecentSearchesType = string[];

export const recentSearchesAtom = atom<string[]>({
  key: 'SEARCH/RECENT_SEARCHES_ATOM',
  default: (() => {
    if (typeof window !== 'undefined') {
      const curRecentSearches = localStorage.getItem('recentSearches');
      return curRecentSearches ? JSON.parse(curRecentSearches) : [];
    }
  })(),
});

export const quickSearchModelNameState = atom<string>({
  key: 'QUICK_SEARCH_MODEL',
  default: '',
});

export const quickSearchOptionNameState = atom<string>({
  key: 'QUICK_SEARCH_OPTION',
  default: '',
});

export const recentSearchPriceRecordState = atom<
  { keyword: string; idx: number }[]
>({
  key: 'SEARCH-KEYWORD-LIST',
  default: [],
});

export const quickSearchRecordState = atom<{
  modelName: [string, boolean[]][];
  optionName: [string, boolean[]][];
}>({
  key: 'QUICK_SEARCH_RECORD',
  default: {
    modelName: [],
    optionName: [],
  },
});
