import { useRouter } from 'next/router';

import { useState, KeyboardEvent, ChangeEvent, useEffect } from 'react';
import { useQuery } from 'react-query';

import { useRecoilValue } from 'recoil';

import { CategoryData } from '@type/web/category';

import { getRecommendKeywords } from '@api/search/search';
import * as SearchAPI from '@api/search/search';

import { categorys } from '../../../../recoils/category/atom';

type PopUpSearchReturnType = {
  data: {
    inputValue: string | string[];
    autoCompleteList: string[];
    category: CategoryData[];
  };
  onClearInputValue: () => void;
  onChangeInputValue: (e: ChangeEvent<HTMLInputElement>) => void;
  onSearchKeyword: (e: KeyboardEvent<HTMLInputElement>) => void;
};

const usePopUpSearch = (tab: 'product' | 'store'): PopUpSearchReturnType => {
  const { category } = useRecoilValue(categorys);
  const router = useRouter();

  const [inputValue, setInputValue] = useState(
    router.query.keyword ? String(router.query.keyword) : '',
  );
  const [autoCompleteList, setAutoCompleteList] = useState<string[]>([]);

  const { data: productRes } = useQuery(
    ['get-product-autocomplete-keyword', inputValue],
    () => (inputValue ? SearchAPI.getRecommendKeywords(inputValue) : undefined),
    {
      enabled: !!inputValue && tab === 'product',
      staleTime: 60000,
      onError(err) {
        console.dir(`검색 자동완성 상품명 리스트 API 호출 에러: ${err}`);
      },
    },
  );
  const { data: storeRes } = useQuery(
    ['get-store-autocomplete-keyword', inputValue],
    () =>
      inputValue ? SearchAPI.getRecommendStoreKeywords(inputValue) : undefined,
    {
      enabled: !!inputValue && tab === 'store',
      staleTime: 60000,
      onError(err) {
        console.dir(`검색 자동완성 가게명 리스트 API 호출 에러: ${err}`);
      },
    },
  );

  // 검색어 초기화
  const onClearInputValue = () => {
    setInputValue('');
    // if (router.pathname === '/search') {
    //   router.replace(
    //     {
    //       pathname: router.pathname,
    //       query: { category: router.query.category || 0, keyword: '' },
    //     },
    //     undefined,
    //     { shallow: true },
    //   );
    // }
  };

  // 검색어 변경
  const onChangeInputValue = async (e: ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  // 검색
  const onSearchKeyword = (e: KeyboardEvent<HTMLInputElement>) => {
    if (!inputValue && e.key === 'Enter') {
      return router.push({
        pathname: `/search`,
        query: { category: router.query.category || 0 },
      });
    }

    if (e.key === 'Enter' && typeof inputValue === 'string') {
      router.push({
        pathname: `/search/${encodeURIComponent(inputValue)}`,
        query: { category: router.query.category || 0 },
      });
    }
  };

  useEffect(() => {
    setAutoCompleteList(
      tab === 'product'
        ? productRes
          ? productRes?.data.autoCompleteItemList.map(
              (keyword) => keyword.keyword,
            )
          : []
        : storeRes
        ? storeRes.data.keywordList.map((keyword) => keyword.nickName)
        : [],
    );
  }, [productRes, storeRes, inputValue, tab]);

  return {
    data: {
      inputValue,
      autoCompleteList,
      category,
    },
    onClearInputValue,
    onChangeInputValue,
    onSearchKeyword,
  };
};

export default usePopUpSearch;
