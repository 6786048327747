import React, { ReactElement } from 'react';

import { HeaderDefaultOptions, NavigationResponseType } from '@type/web/header';

import HeaderNavigationPCTemplete from './HeaderNavigationPCTemplete/HeaderNavigationPCTemplete';

type HeaderNavigationProps = HeaderDefaultOptions & NavigationResponseType;

function HeaderNavigation(props: HeaderNavigationProps): ReactElement {
  return (
    <>
      <HeaderNavigationPCTemplete {...props} />
    </>
  );
}

export default HeaderNavigation;
