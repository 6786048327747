import { AdsResponseConstType } from '@type/api/edge/ads';
import { OfficialSNSIconType } from '@type/web/footer';
import { CategorySortOptions, ProductSortOptions } from '@type/web/search';
import { CommentTabArr, CommentTabType } from '@type/webView/wikiComment';

import { palette } from '@lib/styles/palette';

import {
  linkBlog,
  linkFaceBook,
  linkInstagram,
  linkYoutube,
} from '@components/common/Icon/svg';

/** 앱 패키지 이름 */
export const APP_PACKAGE = process.env.NEXT_PUBLIC_APP_PACKAGE;

/** 앱 스키마 이름 */
export const APP_SCHEMA = process.env.NEXT_PUBLIC_APP_SCHEMA;

/** 개발환경 로그인 허용 */
export const APP_USE_LOGIN_TEST = process.env.NEXT_PUBLIC_USE_LOGIN_TEST;

/** 도메인 정보 */
export const DOMAIN = process.env.NEXT_PUBLIC_DEFAULT_URL;

/** API DOMAIN URL */
// export const {
//   NEXT_PUBLIC_WIKI_API_URL: WIKI_API_URL,
//   NEXT_PUBLIC_WIKI_SEARCH_API_URL: WIKI_SEARCH_API_URL,
//   NEXT_PUBLIC_BOOT_API_URL: BOOT_API_URL,
//   NEXT_PUBLIC_EDGE_API_URL: EDGE_API_URL,
//   NEXT_PUBLIC_API_URL: API_BASE_URL,
//   NEXT_PUBLIC_AUTO_API_URL: AUTO_API_URL,
//   NEXT_PUBLIC_POINT_API_URL: POINT_API_URL,
//   NEXT_PUBLIC_SEARCH_API_URL: SEARCH_API_URL,
//   NEXT_PUBLIC_STANDARD_PRICE_API_URL: STANDARD_PRICE_API_URL,
//   NEXT_PUBLIC_MARKETING_API_URL: MARKETING_API_URL,
//   NEXT_PUBLIC_TICKETS_API_URL: TICKETS_API_URL,
//   NEXT_PUBLIC_ORDER_API_URL: ORDER_API_URL,
//   NEXT_PUBLIC_SHARE_URL: SHARE_URL,
//   NEXT_PUBLIC_IMG_URL: IMG_URL,
//   NEXT_PUBLIC_IMG_WIKI_URL: WIKI_IMG_URL,
//   NEXT_PUBLIC_DEFAULT_MWEB_URL: MWEB_URL,
// } = process.env;

export const SHARE_URL = process.env.NEXT_PUBLIC_SHARE_URL;
export const IMG_URL = process.env.NEXT_PUBLIC_IMG_URL;

/** API KEYS */
export const KAKAO_API_KEY = process.env.NEXT_PUBLIC_KAKAO_API_KEY;

/** 메인 메뉴 목록 */
export const mainMenuList = [
  {
    url: '/mobile',
    text: '중고폰',
  },
  {
    url: '/auto',
    text: '중고차',
  },
  {
    url: '/bike',
    text: '바이크',
  },
  {
    url: '/search-price',
    text: '시세조회',
  },
  {
    url: '/event-list',
    text: '이벤트/콘텐츠',
  },
];

export const deepLinks = [
  {
    deepLink: 'jnapps3://?openweb=',
    webLink: '',
  },
  {
    deepLink: 'jnapps3://?openbrowser=',
    webLink: '',
  },
  {
    deepLink: 'jnapps3://?storeseq=',
    webLink: '/store/',
  },
];

/** 광고 api response key */
export const adsResponseConst: AdsResponseConstType[] = [
  {
    type: 'coupang',
    responseKey: 'coupangAdsResult',
  },
  {
    type: 'naverPowerShopping',
    responseKey: 'naverShoppingAdsResult',
  },
  {
    type: 'naverPowerLink',
    responseKey: 'naverPowerLinkResult',
  },
];

export const OS_TYPE = { APP: 1, WEB: 2 };
export const PLATFORM_TYPE = { APP: 1, AUTO: 2, PEACE_MARKET: 3, CAFE: 4 };

export const ITUNES = 'https://itunes.apple.com/app/id896515652';
export const PLAYSTORE =
  'https://play.google.com/store/apps/details?id=com.elz.secondhandstore&hl=ko';

export const MEDIA_TYPE = {
  IMAGE: 0,
  VIDEO: 1,
} as const;

/** 상품 상태, 0:판매중 / 1:예약중 / 2:판매보류 / 3:판매완료 / 4:상품삭제 / 5:관리자숨김 / 6:신고숨김 */
export const PRODUCT_STATUS = {
  ON_SALE: 0,
  RESERVATION: 1,
  HOLDING: 2,
  SOLD_OUT: 3,
  DELETE: 4,
  HIDE_ADMIN: 5,
  HIDE_REPORT: 6,
};

export const PRODUCT_STATUS_KOR = [
  '판매중', // 0: ON_SALE
  '예약중', // 1: RESERVATION
  '판매보류', // 2: HOLDING
  '판매완료', // 3: SOLD_OUT
  '상품삭제', // 4: DELETE
  '관리자숨김', // 5: HIDE_ADMIN
  '신고숨김', // 6: HIDE_REPORT
];

const MAX_PRICE = 200 * 1000 * 10000;

interface PriceRange {
  key: number;
  text: string;
  value: [number, number];
}

export const priceRange: PriceRange[] = [
  {
    key: 0,
    text: '전체가격',
    value: [0, MAX_PRICE],
  },
  {
    key: 1,
    text: '10만원 이하',
    value: [0, 10 * 10000],
  },
  {
    key: 2,
    text: '10만원 - 30만원 이하',
    value: [10 * 10000, 30 * 10000],
  },
  {
    key: 3,
    text: '30만원 - 50만원 이하',
    value: [30 * 10000, 50 * 10000],
  },
  {
    key: 4,
    text: '50만원 이상',
    value: [50 * 10000, MAX_PRICE],
  },
  {
    key: 5,
    text: '직접입력',
    value: [0, 0],
  },
];

// Wiki
/* Wiki 문서상세 - 글자수 줄임 기준 */
export const WIKI_STRING_LIMIT = {
  STRING_DESC_CNT: 150,
  STRING_TITLE_CNT: 20,
  STRING_DESC_SUMMARY_CNT: 47,
  STRING_COMMENT_CNT: 120,
};

export const WIKI_MAIN_HEADER_HEIGHT = 50;
export const WIKI_COMMENT_SINGLE_LINE_HEIGHT = 22;
export const WIKI_COMMENT_SINGLE_LINE_RADIUS = '100px';
export const WIKI_COMMENT_MULTIPLE_ROW_RADIUS = '11px';
export const WIKI_COMMENT_STRING_LIMIT = 1000;
export const WIKI_COMMENT_IMG_LIMIT = 1024 * 1024 * 5; // 5MB

export const MAX_RECENT_SEARCHES = 10;

/* Wiki 문서정보 - 댓글 type & type별 색상 */
export const WIKI_COMMENT_TYPE_COLOR = {
  NORMAL: palette.wikiGray3,
  TIP: palette.red9,
  ASK: palette.yellow9,
  REPLY: '',
};

export const WIKI_COMMENT_TYPE = {
  NORMAL: '일반',
  TIP: '팁',
  ASK: '문의',
  REPLY: '',
};

export const WIKI_COMMENT_TYPECODE = {
  일반: 'NORMAL',
  팁: 'TIP',
  문의: 'ASK',
};

export const WIKI_COMMENT_TYPE_TXT = {
  NORMAL: '일반',
  TIP: '팁',
  ASK: '문의',
  ALL: '전체',
};

export const wikiCommentTabArr = [
  { tabType: 'ALL', title: '전체' },
  { tabType: 'NORMAL', title: '일반' },
  { tabType: 'TIP', title: '팁' },
  { tabType: 'ASK', title: '문의' },
] as CommentTabArr[];

export const WIKI_COMMENT_HIERARCHICAL = {
  INFO: '정보',
  COMMENT: '댓글',
};

export const DEFAULT_SHARE_IMAGE =
  'https://img2.joongna.com/mystore/share/img_jn_url_share%401x.png';

export const RENDER_QUANTITY = 30;

export const APP_DOWNLOAD_WEB_URL = 'https://www.joongna.com/';

export const PRODUCT_SORT_OPTIONS: ProductSortOptions[] = [
  { sortName: '최신순', sortType: 'NEW' },
  { sortName: '낮은 가격순', sortType: 'LOW' },
  { sortName: '높은 가격순', sortType: 'HIGH' },
];

export const PRODUCT_FILTER_CONDITIONS = [
  { id: 'TOTAL', value: undefined, title: '전체' },
  {
    id: 'ON_SALE',
    value: 0,
    title: '판매중',
  },
  { id: 'RESERVATION', value: 1, title: '예약중' },
  { id: 'SOLD_OUT', value: 3, title: '판매완료' },
];

export const FOOTER_OFFICIAL_SNS: OfficialSNSIconType[] = [
  { icon: 'linkFaceBook', url: 'https://www.facebook.com/joonggonara.m' },
  { icon: 'linkBlog', url: 'https://blog.naver.com/jnofficial' },
  {
    icon: 'linkYoutube',
    url: 'https://www.youtube.com/channel/UCTUQdtrWF8BekcHX-X1SMPw',
  },
  {
    icon: 'linkInstagram',
    url: 'https://www.instagram.com/joonggonara_official/',
  },
];

export const CATEGORY_SORT_OPTIONS: CategorySortOptions[] = [
  { sortType: 'RECENT_SORT', sortName: '최신순', sortOrder: 0 },
  {
    sortType: 'PRICE_ASC_SORT',
    sortName: '낮은가격순',
    sortOrder: 1,
  },
  {
    sortType: 'PRICE_DESC_SORT',
    sortName: '높은가격순',
    sortOrder: 2,
  },
  {
    sortType: 'RECOMMEND_SORT',
    sortName: '인기순',
    sortOrder: 3,
  },
];

export const TERMS_IMPLEMENTATION_DATE_LIST = [
  { date: '2023-01-09', version: 'v1.3' },
  { date: '2022-08-26', version: 'v1.2' },
  { date: '2022-05-02', version: 'v1.1' },
  { date: '2022-03-11', version: 'v1.0' },
  { date: '2021-11-01', version: 'v0.9' },
  { date: '2021-08-17', version: 'v0.8' },
  { date: '2021-01-14', version: 'v0.7' },
  { date: '2020-09-09', version: 'v0.6' },
  { date: '2020-07-30', version: 'v0.5' },
  { date: '2019-08-29', version: 'v0.4' },
  { date: '2019-05-13', version: 'v0.3' },
  { date: '2019-01-03', version: 'v0.2' },
  { date: '2016-02-17', version: 'v0.1' },
];

export const TERMS_LOCATION_DATE_LIST = [
  { date: '2023-04-12', version: 'v0.8' },
  { date: '2021-06-02', version: 'v0.7' },
  { date: '2021-04-23', version: 'v0.6' },
  { date: '2021-04-07', version: 'v0.5' },
  { date: '2020-05-19', version: 'v0.4' },
  { date: '2019-09-11', version: 'v0.3' },
  { date: '2019-01-03', version: 'v0.2' },
  { date: '2015-06-01', version: 'v0.1' },
];

export const appDownloadImageBaseURL =
  'https://common.joongna.com/image/appdownload/';

// 시세조회 api 분기위한 타입
export const actionDetailType = {
  MARKET_PRICE_PRODUCT_DETAIL: {
    text: 'MARKET_PRICE_PRODUCT_DETAIL',
    api: 'PRODUCT',
  },
  MARKET_PRICE_PRODUCT_REGISTER: {
    text: 'MARKET_PRICE_PRODUCT_REGISTER',
    api: 'PRODUCT',
  },
  MARKET_PRICE_MAIN: { text: 'MARKET_PRICE_MAIN', api: 'MAIN' },
  MARKET_PRICE_CAFE: { text: 'MARKET_PRICE_CAFE', api: 'MAIN' },
  MARKET_PRICE_DETAIL_VIEW: { text: 'MARKET_PRICE_DETAIL_VIEW', api: 'MAIN' },
  MARKET_PRICE_SEARCH: { text: 'MARKET_PRICE_SEARCH', api: 'MAIN' },
};

export const searchPriceKeywordSource = {
  LATEST_KEYWORD: 'LATEST_KEYWORD', // 최근검색어
  SUGGESTED_KEYWORD: 'SUGGESTED_KEYWORD', // 자동완성 키워드
  INPUT_KEYWORD: 'INPUT_KEYWORD', // 검색어 직접 입력
  RELATED_KEYWORD: 'RELATED_KEYWORD', // 연관 키워드
  POPULAR_KEYWORD: 'POPULAR_KEYWORD', // 인기 키워드
} as const;
