import { useMemo, useEffect, RefObject, useState } from 'react';

import useResponsive from '@hook/useResponsive';
import useRouterHook from '@hook/useRouterHook';

type QuickMenuType = {
  title: string;
  to: string;
  isCategory?: boolean;
};
interface NavigationListValue {
  activeQuickIndex: number;
  quickMenu: QuickMenuType[];
  serviceMenu: {
    title: string;
    to: string;
  }[];
}

type HeaderNavigationReturnType = {
  activeMenu: 'quickMenu' | 'serviceMenu';
  naviMenu: NavigationListValue;
  dotPosLeft: number | undefined;
  setInitDot: () => void;
  setHoverDot: (
    e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement> | undefined,
  ) => void;
};

const useHeaderNavigation = (
  quickMenuWrap: RefObject<HTMLUListElement>,
  serviceMenuWrap: RefObject<HTMLUListElement>,
): HeaderNavigationReturnType => {
  const {
    location: { pathname },
  } = useRouterHook();
  const { isPC } = useResponsive();

  const naviMenu = useMemo((): NavigationListValue => {
    const menu = {
      activeQuickIndex: 0,
      quickMenu: [
        { title: '홈', to: '/' },
        { title: '이벤트', to: '/event/list' },
        { title: '사기조회', to: '/fraud' },
        // { title: '중고폰', to: '/mobile' },
        // { title: '중고바이크', to: '/bike' },
      ],
      serviceMenu: [
        // { title: '위키백과', to: '/wiki' },
        { title: '시세조회', to: '/search-price' },
        // { title: '우리동네', to: '/hometown' },
      ],
    };

    if (isPC) {
      const category = {
        title: '카테고리',
        to: '/search',
        isCategory: true,
      };
      menu.quickMenu.splice(1, 0, category);
    }

    const matchPathnameIndex = [
      ...menu.quickMenu,
      ...menu.serviceMenu,
    ].findIndex(
      (m) =>
        m.to === pathname ||
        (pathname &&
          pathname !== '/search-price' &&
          m.to.includes(pathname.slice(0, 6))) ||
        (pathname === '/search/product' && m.to === '/search/category'), // 검색 페이지에서는 카테고리를 bold처리
    );

    menu.activeQuickIndex = matchPathnameIndex > 0 ? matchPathnameIndex : 0;

    return menu;
  }, [isPC, pathname]);

  const [dotPosLeft, setDotPosLeft] = useState<number | undefined>();
  const [activeMenu, setActiveMenu] = useState<'quickMenu' | 'serviceMenu'>(
    'quickMenu',
  );

  const setDot = (menuWithDot: HTMLElement) => {
    const className = menuWithDot.closest('div')?.classList[1];
    const dotPos = menuWithDot.offsetLeft + menuWithDot.offsetWidth / 2;

    setDotPosLeft(dotPos);
    setActiveMenu(className === 'quickMenuWrap' ? 'quickMenu' : 'serviceMenu');
  };

  const setInitDot = () => {
    if (!quickMenuWrap || !serviceMenuWrap) return;

    const quickMenuItems = quickMenuWrap.current?.querySelectorAll(
      '.quick-li',
    ) as any;
    const serviceMenuItems = serviceMenuWrap.current?.querySelectorAll(
      '.quick-li',
    ) as any;

    const activeMenu = [...quickMenuItems, ...serviceMenuItems][
      naviMenu.activeQuickIndex
    ] as HTMLLIElement;

    if (activeMenu) {
      setDot(activeMenu);
    }
  };

  const setHoverDot = (
    e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement> | undefined,
  ) => {
    const parent = e?.currentTarget.parentNode;

    if (parent instanceof HTMLLIElement) {
      setDot(parent);
    }
  };

  useEffect(() => {
    setInitDot();
    window.addEventListener('resize', setInitDot);
    return () => {
      window.removeEventListener('resize', setInitDot);
    };
  }, [naviMenu]);

  return {
    activeMenu,
    naviMenu,
    dotPosLeft,
    setInitDot,
    setHoverDot,
  };
};

export default useHeaderNavigation;
