import React, { ReactElement } from 'react';

import { MOBILE_WIDTH, TABLET_WIDTH } from '@common/constants/MEDIAS';
import useOpenAppWithWebUrl from '@common/deepLinking/useOpenAppWithWebUrl';
import { css } from '@emotion/react';
import { HeaderRightButtonTypes } from '@type/web/header';

import { palette } from '@lib/styles/palette';

import usePortal from '@hook/usePortal';

import Button from '@components/common/Button/Button';
import Icon from '@components/common/Icon/Icon';
import PopUpSearch from '@components/web/popup/PopUpSearch/PopUpSearch';

type RightBtnsType = {
  type: typeof HeaderRightButtonTypes[keyof typeof HeaderRightButtonTypes];
}[];

interface HeaderRightButtonsProps {
  isTopPosition?: boolean;
  btns?: RightBtnsType;
  onClickShareData?: () => void;
}

function HeaderRightButtons({
  isTopPosition,
  btns,
  onClickShareData,
}: HeaderRightButtonsProps): ReactElement {
  const {
    PopUp: SearchProductPopUp,
    onShowPopUp,
    onHidePopUp,
  } = usePortal('PagePopUp');

  const openAppWithWebUrl = useOpenAppWithWebUrl();
  const handleOpenApp = () => {
    openAppWithWebUrl();
  };

  const getBtns = (types: RightBtnsType) =>
    types?.map((btn) => {
      switch (btn.type) {
        case 'appDownload':
          return (
            <Button
              type="light"
              shape="round"
              padding="7px 8px"
              key={btn.type}
              styleProps={mobileOnly}
              color={isTopPosition ? palette.white : ''}
              borderColor={isTopPosition ? palette.white : ''}
              fontSize="12px"
              height="28px"
              onClick={handleOpenApp}
            >
              앱으로 보기
            </Button>
          );
        case 'category':
          return (
            <div key={btn.type}>
              <Button
                type="link"
                padding="0"
                onClick={onShowPopUp}
                aria-label="categories"
              >
                <Icon
                  icon={btn.type}
                  size={24}
                  stroke={isTopPosition ? palette.white : palette.black10}
                />
              </Button>
              <SearchProductPopUp>
                <PopUpSearch onClosePopUp={onHidePopUp} />
              </SearchProductPopUp>
            </div>
          );
        case 'shareIOS':
          return (
            <Button type="link" padding="0" onClick={onClickShareData}>
              <Icon
                icon={btn.type}
                size={24}
                stroke={isTopPosition ? palette.white : palette.black10}
              />
            </Button>
          );
        default:
          return null;
      }
    });

  if (!btns) return <></>;

  return <div css={wrapperStyle}>{getBtns(btns)}</div>;
}

const wrapperStyle = css`
  display: flex;
  gap: 15px;
`;

const mobileOnly = css`
  display: none;

  @media (max-width: ${MOBILE_WIDTH}) {
    display: inline;
  }
`;

export default HeaderRightButtons;
