import { InterfaceProductDetailDataType } from '@type/webView/interface';

import {
  isAndroidApp,
  isFlutterApp,
  isIOSApp,
  getAppDataByStorage,
} from './webview';

const getAosInterface = () => typeof window !== 'undefined' && window.qdillion;
const getIosInterface = () =>
  typeof window !== 'undefined' &&
  window.webkit &&
  window.webkit.messageHandlers;
const getFlutterInterface = () =>
  typeof window !== 'undefined' && window.flutter_inappwebview;

/* 중복 로직을 줄이기 위한 인터페이스 호출 함수 / 테스트 후 적용 예정 */
// export const setInterfaceCall = (eventName: string, allTypeParams?: any) => {
//   const aosInterface = isAndroidApp() && getAosInterface();
//   const iosInterface = isIOSApp() && getIosInterface();
//   const flutterInterface = isFlutterApp() && getFlutterInterface();

//   const params = allTypeParams ? allTypeParams : '';

//   if (flutterInterface) {
//     flutterInterface.callHandler(eventName, params);
//     return;
//   }

//   if (aosInterface[eventName]) {
//     aosInterface[eventName](allTypeParams);
//     return;
//   }

//   if (iosInterface[eventName]) {
//     iosInterface[eventName].postMessage(params);
//   }
// };

/** 앱 정보 요청 (토큰정보 포함) */
export const initGetAppInfo = (): void => {
  const aosInterface = getAosInterface();
  const iosInterface = getIosInterface();
  const flutterInterface = getFlutterInterface();

  if (isFlutterApp()) {
    window.addEventListener('flutterInAppWebViewPlatformReady', () => {
      window.localStorage.setItem('flutterReady', 'true');
      window.flutter_inappwebview.callHandler('getAppInfo', '');
    });
  } else {
    if (isAndroidApp() && aosInterface.getAppInfo) {
      aosInterface.getAppInfo();
    }
    if (isIOSApp() && iosInterface.getAppInfo) {
      iosInterface.getAppInfo.postMessage('');
    }
  }
};

export const getAppInfo = (): void => {
  const aosInterface = getAosInterface();
  const iosInterface = getIosInterface();
  const flutterInterface = getFlutterInterface();

  if (isFlutterApp() && flutterInterface) {
    window.flutter_inappwebview.callHandler('getAppInfo', '');
  } else {
    if (isAndroidApp() && aosInterface.getAppInfo) {
      aosInterface.getAppInfo();
    }
    if (isIOSApp() && iosInterface.getAppInfo) {
      iosInterface.getAppInfo.postMessage('');
    }
  }
};

export const setLightStatusBar = (): void => {
  const aosInterface = getAosInterface();
  const iosInterface = getIosInterface();
  const flutterInterface = getFlutterInterface();

  if (isFlutterApp() && flutterInterface) {
    window.flutter_inappwebview.callHandler('lightStatusbar', '');
  } else {
    if (isAndroidApp() && aosInterface.lightStatusbar) {
      aosInterface.lightStatusbar();
    }
    if (isIOSApp() && iosInterface.lightStatusbar) {
      iosInterface.lightStatusbar.postMessage('');
    }
  }
};

export const setDarkStatusBar = (): void => {
  const aosInterface = getAosInterface();
  const iosInterface = getIosInterface();
  const flutterInterface = getFlutterInterface();

  if (isFlutterApp()) {
    window.flutter_inappwebview.callHandler('darkStatusbar', '');
  } else {
    if (aosInterface.darkStatusbar) {
      aosInterface.darkStatusbar();
    }
    if (iosInterface.darkStatusbar) {
      iosInterface.darkStatusbar.postMessage('');
    }
  }
};

/** 웹 뷰 닫기 */
export const closeWebView = (): void => {
  const aosInterface = getAosInterface();
  const iosInterface = getIosInterface();
  const flutterInterface = getFlutterInterface();

  if (isFlutterApp() && flutterInterface) {
    window.flutter_inappwebview.callHandler('close', '');
    window.localStorage.removeItem('flutterReady');
  } else {
    if (isAndroidApp() && aosInterface.darkStatusbar) {
      aosInterface.close();
    }
    if (isIOSApp() && iosInterface.darkStatusbar) {
      iosInterface.closeEnd.postMessage('');
    }
  }
};

/** 로그인 팝업 오픈 요청 */
export const showLoginPopup = (url = window.location.href): void => {
  const aosInterface = getAosInterface();
  const iosInterface = getIosInterface();
  const flutterInterface = getFlutterInterface();

  if (isFlutterApp()) {
    window.flutter_inappwebview.callHandler('openLoginPopup', url);
  } else {
    if (isAndroidApp() && aosInterface.openLoginPopup) {
      aosInterface.openLoginPopup(url);
    }
    if (isIOSApp() && iosInterface.openLoginPopup) {
      iosInterface.openLoginPopup.postMessage(url);
    }
  }
};

/** 카카오톡 공유하기 */
export const interfaceShareKakao = (content: any): void => {
  const aosInterface = getAosInterface();
  const iosInterface = getIosInterface();
  const flutterInterface = getFlutterInterface();

  if (isFlutterApp() && flutterInterface) {
    window.flutter_inappwebview.callHandler(
      'shareKakao',
      JSON.stringify(content),
    );
  } else {
    if (isAndroidApp() && aosInterface.shareKakao) {
      window.qdillion.shareKakao(JSON.stringify(content));
    }
    if (isIOSApp() && iosInterface.shareKakao) {
      window.webkit.messageHandlers.shareKakao.postMessage(
        JSON.stringify(content),
      );
    }
  }
};

/** 앱 상품상세 open interface */
export const interfaceOpenAppProductDetail = (
  data: InterfaceProductDetailDataType,
) => {
  const aosInterface = getAosInterface();
  const iosInterface = getIosInterface();
  const flutterInterface = getFlutterInterface();

  if (isFlutterApp() && flutterInterface) {
    window.flutter_inappwebview.callHandler(
      'openProductDetail',
      JSON.stringify(data),
    );
  } else {
    if (isAndroidApp() && aosInterface.openProductDetail) {
      window.qdillion.openProductDetail(JSON.stringify(data));
    }
    if (isIOSApp() && iosInterface.openProductDetail) {
      window.webkit.messageHandlers.openProductDetail.postMessage(
        JSON.stringify(data),
      );
    }
  }
};

// 안드로이드 뒤로가기 작동 여부
export const interfaceAosBackHold = (isBack = false) => {
  const aosInterface = getAosInterface();
  const iosInterface = getIosInterface();
  const flutterInterface = getFlutterInterface();

  if (isFlutterApp() && flutterInterface) {
    window.flutter_inappwebview.callHandler('setBackHold', isBack);
    return;
  }

  if (
    isAndroidApp() &&
    aosInterface.setBackHold &&
    compareAppVersionIsLatest('7.0.7')
  ) {
    aosInterface.setBackHold(isBack);
  }
};

/** 앱 버전 체크
 * @param versionNum ex) 7.2.3
 */
export const compareAppVersionIsLatest = (versionNum: string) => {
  const data = getAppDataByStorage(true);

  // UserAgent가 없을 경우
  if (data === null) {
    return false;
  }

  const baseVersion = versionNum.split('.').map(Number);
  const nowVersion = data['App-Version'].split('.').map(Number);

  for (let i = 0; i < nowVersion.length; i += 1) {
    baseVersion[i] = baseVersion[i] || 0;
    if (nowVersion[i] > baseVersion[i]) {
      return true;
    }
    if (nowVersion[i] !== baseVersion[i]) {
      return false;
    }
  }
  return baseVersion.length <= nowVersion.length;
};
