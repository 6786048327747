import Link from 'next/link';
import { useRouter } from 'next/router';

import React, { ReactElement } from 'react';

import { css } from '@emotion/react';

import { palette } from '@lib/styles/palette';

import usePortal from '@hook/usePortal';

import Icon from '@components/common/Icon/Icon';
import Logo from '@components/common/Logo/NewLogo';
import SpaceLayout from '@components/web/Layout/SpaceLayout';
import RankKeyword from '@components/web/RankKeyword/RankKeyword';
import { PcTemplate } from '@components/web/common/responsiveTemplateStyle';
import PopUpSearch from '@components/web/popup/PopUpSearch/PopUpSearch';

function HeaderPrimaryPC(): ReactElement {
  const {
    PopUp: SearchProductPopUp,
    onShowPopUp,
    onHidePopUp,
  } = usePortal('PagePopUp');

  const { query } = useRouter();

  // 검색창 클릭시 검색 팝업 노출
  const onClickSearchBar = () => {
    onShowPopUp();
  };

  return (
    <PcTemplate>
      <SpaceLayout padding={[16, 16]}>
        <div css={primaryPC(query.keyword)}>
          {/* 헤더 햄버거 메뉴 삭제 */}
          {/* <Button
            to="/search/category?category=0"
            type="link"
            color={palette.black10}
            padding="0"
            leftIcon={<Icon icon="menu" stroke={palette.black10} size={24} />}
            className="category"
          /> */}
          <Link href="/">
            <a>
              <Logo width={159} height={25} />
            </a>
          </Link>
          <div className="searchWrap">
            <button
              type="button"
              aria-label="keywordSearch"
              className="searchBar"
              onClick={onClickSearchBar}
            >
              {query.keyword || '어떤 상품을 찾으시나요?'}
            </button>
            <div className="searchIcon">
              <Icon icon="search" size={20} stroke={palette.gray6} />
            </div>
          </div>
          <div className="rank">
            <RankKeyword />
          </div>
        </div>
        <SearchProductPopUp>
          <PopUpSearch onClosePopUp={onHidePopUp} />
        </SearchProductPopUp>
      </SpaceLayout>
    </PcTemplate>
  );
}

const primaryPC = (keyword: string | string[] | undefined) => css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;

  .category {
    margin-right: 30px;
  }

  .searchWrap {
    flex: 1;
    margin: 0 5%;
    position: relative;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    .searchBar {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 100%;
      height: 44px;
      padding: 0 20px;
      border: none;
      border-radius: 30px;
      background: ${palette.gray0};
      box-shadow: none;
      text-align: left;
      font-size: 14px;
      color: ${keyword ? palette.black10 : palette.gray5};
    }
    .searchIcon {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 44px;
      height: 44px;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 10px;
    }
  }
  .rank {
    width: 244px;
    height: 44px;
    position: relative;
    z-index: 2;
    .rank {
      width: 100%;
      height: auto;
      position: absolute;
      top: 0;
      right: 0;
    }
  }
`;

export default HeaderPrimaryPC;
