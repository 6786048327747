import { useRouter } from 'next/router';

import { useCallback } from 'react';
import { isDesktop } from 'react-device-detect';

const useOpenAppWithWebUrl = () => {
  const router = useRouter();

  return useCallback(() => {
    if (isDesktop) {
      return router.push('/appdownload');
    }
    const {
      articleSeq,
      productSeq,
      eventSeq,
      storeSeq,
      keyword,
      category,
      date,
    } = router.query;
    const webUrlPath = router.pathname;
    /** Airbridge 링크 사용 가이드: https://help.airbridge.io/hc/ko/articles/900005012543 */
    // const baseUrl = 'https://tracking.joongna.com/@joonggonara/webdev?'; // webdev = 웹개발팀 전용 Airbridge 채널
    // const deepLinkParam = 'deeplink_url=jnapps3://?';
    // const additionalParams = '&campaign=non_DEV&ad_type=click'; // non_DEV = 비개발팀 전용 캠페인 속성
    const deepLinkScheme = 'jnapps3://?';
    const deepLinkPath =
      (articleSeq && `cafeproductseq=${articleSeq}`) ||
      (productSeq && `productseq=${productSeq}`) ||
      (eventSeq && `eventseq=${eventSeq}`) ||
      (storeSeq && `storeseq=${storeSeq}`) ||
      (webUrlPath === '/event/list' && 'applink=eventlist') ||
      (webUrlPath === '/search-price' && `appLink=searchPrice`) ||
      (webUrlPath.includes('/point-event') &&
        date &&
        `pointevent=2&date=${date}`) ||
      /** categoryseq 가 0인 경우, 앱링크에서 인식하지 못함
       * category와 키워드 검색 시, categoryseq가 0인 경우와 그렇지 않은 경우를 구분해야 함.
       */
      // (0이 아닌)카테고리 + 키워드 검색
      (webUrlPath.includes('/search') &&
        keyword &&
        category &&
        category !== '0' &&
        `categoryseq=${category}&keyword=${keyword}`) ||
      // 카테고리 선택 없이(= 0인 경우), 키워드만 검색
      (webUrlPath.includes('/search') &&
        keyword &&
        category &&
        category === '0' &&
        `searchword=${keyword}`) ||
      // (0이 아닌) 카테고리만 검색
      (webUrlPath.includes('/search') &&
        category &&
        category !== '0' &&
        `categoryseq=${category}`) ||
      // 카테고리 메인 (카테고리 + 키워드 모두 없음)
      (webUrlPath.includes('/search') &&
        !keyword &&
        category &&
        category === '0' &&
        `applink=categorymain`) ||
      'applink=main';
    /**
     * Airbridge URL
     * 예: https://tracking.joongna.com/@joonggonara/webdev?deeplink_url=jnapps3://?productseq=111111&campaign=non_DEV&ad_type=click
     */
    // return (window.location.href = `${baseUrl}${deepLinkParam}${deepLinkPath}${additionalParams}`);
    window.airbridge.openDeeplink({
      type: 'click', // click || redirect
      deeplinks: {
        ios: `${deepLinkScheme}${deepLinkPath}`,
        android: `${deepLinkScheme}${deepLinkPath}`,
      },
      fallbacks: {
        ios: 'itunes-appstore',
        android: 'google-play',
      },
      desktopPopUp: true,
    });
  }, [router]);

  /** (구)로직 : */
  // const openApp = useOpenApp();
  // return useCallback(
  //   (url: string, goAppStore = true) => {
  //     if (isDesktop) {
  //       window.open(APP_DOWNLOAD_WEB_URL);
  //       return null;
  //     }
  //     const deepLink = getDeepLink(url);
  //     if (deepLink) openApp(deepLink, goAppStore);
  //     else if (window.confirm('앱스토어로 이동하시겠습니까?')) {
  //       window.location.href = isAndroid ? PLAYSTORE : ITUNES;
  //     }
  //     return deepLink;
  //   },
  //   [openApp],
  // );
};

export default useOpenAppWithWebUrl;
