import { atom, selector } from 'recoil';

import { CategoryListData, CategoryType } from '@type/web/category';

import { getCategories } from '@api/base/category';

export const totalCategories = selector<CategoryType[]>({
  key: 'CATEGORY/TOTAL_CATEGORYS',
  get: async () => {
    const { data: categories } = await getCategories();
    return categories;
  },
});

export const categorys = atom<CategoryListData>({
  key: 'CATEGORY/CATEGORYS',
  default: {
    totalCount: 0,
    category: [],
  },
});

export const categoryDepthCnt = atom<number>({
  key: 'CATEGORY/CATEGORY_DEPTH',
  default: 0,
});
