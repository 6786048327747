import { ReactElement, ReactNode } from 'react';

import { TABLET_WIDTH, MOBILE_WIDTH } from '@common/constants/MEDIAS';
import { css } from '@emotion/react';

import sizeType from '@lib/styles/sizeType';

interface SpaceLayoutProps {
  children?: ReactNode;
  margin?: [number, number];
  padding?: [number, number];
  border?: [string, string];
}

const SpaceLayout = ({
  children,
  margin = [0, 0],
  padding = [0, 0],
  border = ['', ''],
}: SpaceLayoutProps): ReactElement => {
  return <div css={spaceLayout(margin, padding, border)}>{children}</div>;
};

const spaceLayout = (
  margin: [number, number],
  padding: [number, number],
  border: [string, string],
) => css`
  max-width: ${sizeType.sizeLayout.large};
  margin: auto;
  position: relative;
  padding-left: 40px;
  padding-right: 40px;
  ${margin.map((value: number, i: number): string => {
    if (i === 0) return `margin-top: ${value}px`;
    if (i === 1) return `margin-bottom: ${value}px`;
    return '';
  })}
  ${padding.map((value: number, i: number): string => {
    if (i === 0) return `padding-top: ${value}px`;
    if (i === 1) return `padding-bottom: ${value}px`;
    return '';
  })}
  ${border.map((value: string, i: number): string => {
    if (i === 0) return `border-top: ${value}`;
    if (i === 1) return `border-bottom: ${value}`;
    return '';
  })}
  
  @media (max-width: ${TABLET_WIDTH}) {
    padding-left: 24px;
    padding-right: 24px;
  }

  @media (max-width: ${MOBILE_WIDTH}) {
    padding-left: 20px;
    padding-right: 20px;
  }
`;

export default SpaceLayout;
