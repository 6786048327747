import Link from 'next/link';

import React, { ReactElement, useMemo, ReactNode } from 'react';
import Slider from 'react-slick';

import dayjs from 'dayjs';

import { TABLET_WIDTH } from '@common/constants/MEDIAS';
import { changeOmitText } from '@common/parser';
import { css } from '@emotion/react';
import { PopularWordItem } from '@type/api/base/mainpage';

import { palette } from '@lib/styles/palette';

import Icon from '@components/common/Icon/Icon';

interface RankKeywordPreviewSwipeProps {
  keywordList: PopularWordItem[];
  reloadDateTime: string;
  setIsShowDetail: (
    cbOrIsShow: ((isShow: boolean) => boolean) | boolean,
  ) => void;
}

function RankKeywordDetailSwipe({
  keywordList,
  reloadDateTime,
  setIsShowDetail,
}: RankKeywordPreviewSwipeProps): ReactElement {
  const keywordListGroup = useMemo((): { [key: string]: PopularWordItem[] } => {
    const firstList = keywordList.slice(0, 5);
    const secondList = keywordList.slice(5, 10);
    const thirdList = keywordList.slice(10, 15);
    const fourthList = keywordList.slice(15, 20);

    let result = {};
    for (let i = 0; i < firstList.length; i += firstList.length) {
      const addList = firstList.slice(i, i + firstList.length);
      result = {
        ...result,
        [`${i + 1} - ${(i += firstList.length)}위`]: addList,
      };
    }
    if (secondList.length) {
      for (let i = 0; i < secondList.length; i += secondList.length) {
        const addList = secondList.slice(i, i + secondList.length);
        result = {
          ...result,
          [`${i + 6} - ${(i += secondList.length + 5)}위`]: addList,
        };
      }
    }
    if (thirdList.length) {
      for (let i = 0; i < thirdList.length; i += thirdList.length) {
        const addList = thirdList.slice(i, i + thirdList.length);
        result = {
          ...result,
          [`${i + 11} - ${(i += thirdList.length + 10)}위`]: addList,
        };
      }
    }
    if (fourthList.length) {
      for (let i = 0; i < fourthList.length; i += fourthList.length) {
        const addList = fourthList.slice(i, i + fourthList.length);
        result = {
          ...result,
          [`${i + 16} - ${(i += secondList.length + 15)}위`]: addList,
        };
      }
    }

    return result;
  }, [keywordList]);

  const toggleIsShowDetail = () => setIsShowDetail((v) => !v);

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 700,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    appendDots: (dots: []) => (
      <ul>
        {dots.map((dot: { key: string; props: { className: string } }) => (
          <li
            key={dot.key}
            className={`pageDot ${dot.props.className && 'active'}`}
          />
        ))}
      </ul>
    ),
  };

  return (
    <div css={rankDetail}>
      <div className="title">
        <p>실시간 검색어</p>
        <div>
          {dayjs(reloadDateTime).format('YYYY.MM.DD HH:mm')} 기준
          <button
            type="button"
            aria-label="show"
            className="button"
            onClick={toggleIsShowDetail}
          >
            <Icon icon="arrowUp" size={14} stroke={palette.black10} />
          </button>
        </div>
      </div>
      <Slider className="detailSwipe" {...sliderSettings}>
        {Object.keys(keywordListGroup).map(
          (key: string, groupIndex: number) => (
            <div key={`${groupIndex + 1}_${key}`}>
              {keywordListGroup[key].map(
                (keyword: PopularWordItem, i: number): ReactNode => {
                  const rank = groupIndex * 5 + i + 1;
                  return (
                    <Link
                      href={`/search/${encodeURIComponent(
                        keyword.popularWord,
                      )}`}
                      key={`${keyword.popularWord}_${keyword.popularWordRegDate}`}
                      passHref
                    >
                      <a className="keyword">
                        <span css={numbering(true)}>{rank}</span>
                        {changeOmitText(15, keyword.popularWord)}
                      </a>
                    </Link>
                  );
                },
              )}
            </div>
          ),
        )}
      </Slider>
    </div>
  );
}

const rankDetail = css`
  display: none;

  @media (max-width: ${TABLET_WIDTH}) {
    display: block;
    background: ${palette.white};
    position: relative;
    z-index: 1;

    .title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 56px;

      > p {
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
      }

      > div {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: right;
        font-size: 12px;
        color: ${palette.gray5};

        .button {
          padding: 0 4px 0 10px;
          background: none;
        }
      }
    }

    .detailSwipe {
      padding-bottom: 20px;

      .slick-dots {
        position: unset;
        margin-top: 16px;

        > li.pageDot {
          width: 4px;
          height: 4px;
          margin: 0 2px;
          padding: 0;
          background: rgba(0, 0, 0, 0.2);
          border-radius: 2px;
          text-align: center;
          text-indent: -999px;
          vertical-align: top;
          transition: all 0.3s;

          &.active {
            width: 24px;
            background: rgba(0, 0, 0, 0.3);
            border-color: rgba(0, 0, 0, 0.3);
          }
        }
      }
      a {
        display: flex;
        align-items: center;
        height: 40px;
        color: ${palette.black10};

        span {
          width: 40px;
        }
      }
    }
  }
`;

const numbering = (isTopRank: boolean) => css`
  color: ${isTopRank ? palette.orange9 : palette.black10};
`;
export default RankKeywordDetailSwipe;
