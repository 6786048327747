import router, { useRouter } from 'next/router';

import { useMemo } from 'react';

import qs from 'qs';

import getQueries from '@common/path/getQueries';

const useRouterHook = () => {
  const router = useRouter();
  const { query: params, pathname } = useRouter();
  const queryString: any =
    typeof window !== 'undefined' && getQueries(window.location.href);
  const location: any = typeof window !== 'undefined' && window.location;

  const push = (path = '/') => {
    router.push(path);
  };

  const shallowPush = (path = '/') => {
    router.push(path, undefined, { shallow: true });
  };

  const replace = (path = '/') => {
    router.replace(path);
  };

  const updateQueryString = (addQueryString: Record<string, string>) => {
    const newQueryString = {
      ...queryString,
      ...addQueryString,
    };
    const parameter = qs.stringify(newQueryString, {
      encodeValuesOnly: true,
    });

    return parameter;
  };

  const pushQueryString = (
    addQueryString: Record<string, string>,
    pathname_?: string,
  ) => {
    const parameter = updateQueryString(addQueryString);

    push(`${pathname_ || pathname}?${parameter}`);
  };

  return {
    router,
    back: router.back,
    location,
    params,
    push,
    shallowPush,
    replace,
    queryString,
    updateQueryString,
    pushQueryString,
  };
};

export default useRouterHook;
